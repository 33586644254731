import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from './context/SnackbarContext';
import { AuthScreen } from './components/auth';
import { NavigationProvider } from './context/NavigationContext';
import SearchBusiness from './pages/SearchBusiness';
import CorpDetailed from './pages/CorpDetailed';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import CashAnalysis from './pages/CashAnalysis';
import IndexPage from './pages/IndexPage';
import { AuthProvider } from './context/AuthContext';
import MyPlanPage from './pages/MyPlanPage';

// import './assets/styles/Auth.css';

const App = () => {
  return (
    <SnackbarProvider>
      <AuthProvider>
        <Router>
          <NavigationProvider>
            <Routes>
              <Route path="/" element={<IndexPage />} />
              <Route path="/signin" element={<PublicRoute element={AuthScreen} />} />
              <Route path="/search" element={<PrivateRoute element={SearchBusiness} />} />
              <Route path="/cash" element={<PrivateRoute element={CashAnalysis} />} />
              <Route path="/myplan" element={<PrivateRoute element={MyPlanPage} />} />
              <Route path="/:companyID" element={<PrivateRoute element={CorpDetailed} />} />
            </Routes>
          </NavigationProvider>
        </Router>
      </AuthProvider>
    </SnackbarProvider >
  );
};

export default App;
