import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, Card, CardContent, CardHeader, useMediaQuery, Grid, Button, Typography } from '@mui/material';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import { formatMonth, shortenCashNumbers } from '../../../utils/utils';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import PaymentsTable from './PaymentsTable';
import { myDebugPrint } from '../../../utils/debug';
import CashCardTemplate from './CashCardTemplate';
import OverallsByTransactionTypesTable from './OverallsByTransactionTypesTable';
import MyOverlay from '../../common/MyOverlay';
import CircleLoader from 'react-spinners/CircleLoader';
import RecalculateProjectCard from './RecalculateProjectCard';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OverallByTransactionTypes = ({ transactionData, projectId, updateCashProjectPayment }) => {
    const [isSlicer, setIsSlicer] = useState(true);
    const [isPayments, setIsPayments] = useState(false);
    const toggleSlicer = () => setIsSlicer(!isSlicer);
    const [paymentsKey, setPaymentsKey] = useState(0); // Add a state to manage the key

    const togglePayments = ({ turnOn }) => {
        setIsPayments(turnOn ?? !isPayments);
        setPaymentsKey(prevKey => prevKey + 1); // Increment the key to force remount
    };

    // Parse the transaction data and categorize by transaction type
    const parseTransactionDataByType = (data) => {

        const inflows = {};
        const outflows = {};
        const transactionTypes = new Set();
        let earliestDate = null;
        let latestDate = null;

        if (!data) return { inflows, outflows, transactionTypes: [], earliestDate, latestDate };

        data.forEach((item) => {
            const month = `${item.TransactionYear}-${item.TransactionMonth.toString().padStart(2, '0')}`;
            const type = item.TransactionTypes;

            if (!inflows[month]) inflows[month] = {};
            if (!outflows[month]) outflows[month] = {};

            inflows[month][type] = (inflows[month][type] || 0) + item.Inflows;
            outflows[month][type] = (outflows[month][type] || 0) + item.Outflows;
            transactionTypes.add(type);

            const date = new Date(item.TransactionYear, item.TransactionMonth - 1);
            if (!earliestDate || date < earliestDate) earliestDate = date;
            if (!latestDate || date > latestDate) latestDate = date;
        });

        return { inflows, outflows, transactionTypes: Array.from(transactionTypes), earliestDate, latestDate };
    };

    const { inflows, outflows, transactionTypes, earliestDate, latestDate } = parseTransactionDataByType(transactionData);

    // Get all months in the range
    const getMonthsInRange = (start, end) => {
        const months = [];
        let current = new Date(start);

        while (current <= end) {
            const month = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}`;
            months.push(month);
            current.setMonth(current.getMonth() + 1);
        }

        return months;
    };

    const getMonthsInText = (months) => {
        return months.map(month => formatMonth(month));
    };

    const months = getMonthsInRange(earliestDate, latestDate);
    const monthsInText = getMonthsInText(months);

    const [selectedTypes, setSelectedTypes] = useState(transactionTypes);

    const toggleType = (type) => {
        togglePayments({ turnOn: false });
        setSelectedTypes(prev =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    const selectAll = () => {
        setSelectedTypes(transactionTypes);
        togglePayments({ turnOn: false });
    };

    const deselectAll = () => {
        setSelectedTypes([]);
        togglePayments({ turnOn: false });
    };

    // Filtered data
    const getFilteredData = (data, types) => {
        return months.map(month =>
            types.reduce((acc, type) => acc + (data[month]?.[type] || 0), 0)
        );
    };

    const filteredInflows = getFilteredData(inflows, selectedTypes);
    const filteredOutflows = getFilteredData(outflows, selectedTypes);

    // Prepare chart data
    const data = {
        labels: monthsInText,
        datasets: [
            {
                label: 'Поступления',
                data: filteredInflows,
                backgroundColor: inflowsColor,
            },
            {
                label: 'Платежи',
                data: filteredOutflows,
                backgroundColor: outflowsColor,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return shortenCashNumbers({ number: value });
                    }
                }
            }
        },
    };

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const slicerGridSize = isSmallScreen ? 12 : 6;

    const [isFolded, setIsFolded] = useState(false);

    return (
        <CashCardTemplate
            title="Обороты по типам транзакций"
            subheader="счета, открытые в руб."
            action={
                <ToggleIconButton
                    icon={<FilterAltIcon />}
                    isOn={isSlicer}
                    onSelectAll={toggleSlicer}
                    onDeselectAll={toggleSlicer}
                />
            }
            cardcontent={
                <CardContent>
                    <Box position='relative'>
                        {!transactionData &&
                            <MyOverlay alignItems='start'>
                                <Box sx={{ maxWidth: '300px', marginTop: '45px', }} >
                                    <Typography variant='body2'>
                                        Невозможно подвести итоги по проекту.
                                        <br />
                                        Обновите страницу и, если данные не появятся, напишите
                                        в поддержку info@brpadvice.ru
                                    </Typography>
                                </Box>
                            </MyOverlay>
                        }
                        {isSlicer &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={slicerGridSize} >
                                    <Slicer
                                        title='Тип транзакциии'
                                        items={transactionTypes}
                                        selectedItems={selectedTypes}
                                        onToggle={toggleType}
                                        onSelectAll={selectAll}
                                        onDeselectAll={deselectAll}
                                        order={true}
                                    />
                                </Grid>
                            </Grid>
                        }
                        <Bar data={data} options={options} />
                        <OverallsByTransactionTypesTable
                            inflows={filteredInflows}
                            outflows={filteredOutflows}
                            months={months}
                        />
                    </Box>
                    <Button
                        variant='outlined'
                        onClick={togglePayments}
                    >
                        {!isPayments ? 'Показать' : 'Скрыть'}&nbsp;платежи
                    </Button>
                    {isPayments
                        ?
                        <PaymentsTable
                            key={paymentsKey} // Add the key prop
                            projectId={projectId}
                            transactionTypes={selectedTypes}
                            currencyCode={'810'}
                            recordsPerPage={10}
                            updateCashProjectPayment={updateCashProjectPayment}
                        />
                        : ''
                    }
                </CardContent>
            }

        />
    );
};

export default OverallByTransactionTypes;


