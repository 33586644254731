import axios, { AxiosError } from 'axios';
import { storeUsername, storeEmail, storeName, storeToken, getToken, getEmail } from './storage';
import { hashPassword } from './utils';
import { unhandledNetworkIssue, slowNetwork, unauthorized, emptyResponse } from './vars';
import Entity from '../classes/Entity';
import PersonData from '../classes/PersonData';
import { myDebugPrint } from './debug';
import CashProjectClass from '../classes/cash/CashProjectClass';
import { json } from 'react-router-dom';

// Import necessary utilities and classes (to be implemented)
// import { hashPassword, Endpoints, handleErrors, emptyResponse, unhandledNetworkIssue, unauthorized, unauthorizedExpired, wrongtoken, wrongcredentials, slowNetwork, noNetwork, error500 } from './utils';

const apiURL = process.env.REACT_APP_API_URL;
const ak = process.env.REACT_APP_API_KEY;

const Endpoints = {
    auth: 'ola/',
    logs: 'deb/',
    search: 'search/',
    detailedCorp: 'detailedCorp/',
    cashAnalysis: 'cashUpload/',
    cashGetProjectSummary: 'cashGetSummary/',
    cashEditProject: 'cashEditProject/',
    cashGetPayments: 'cashGetPayments/',
    cashGetCounterparties: 'cashGetCounterparties/',
    cashProjectsList: 'cashProjectsList/',
    cashEditPayment: 'cashEditPayment/',
    apply: 'sup/',
    myPlan: 'myPlan/',
    myHistory: 'myHistory/',
};

class ApiManager {
    constructor() {
        this._callCounts = {};
        this._lastCallTimestamps = {};
    }

    async _genericApiCall(key) {
        const now = new Date();

        if (this._lastCallTimestamps[key] && (now - this._lastCallTimestamps[key]) / 1000 > 5) {
            this._callCounts[key] = 1;
        } else {
            this._callCounts[key] = (this._callCounts[key] ?? 0) + 1;
        }

        this._lastCallTimestamps[key] = now;

        const delay = this._getDelay(this._callCounts[key] ?? 1);
        await new Promise(resolve => setTimeout(resolve, delay));
    }

    _getDelay(count) {
        switch (count) {
            case 1:
                return 0;
            case 2:
                return 300;
            case 3:
                return 1000;
            case 4:
                return 2000;
            default:
                return 5000;
        }
    }

    async checkForToken(jsonResponse) {
        if (jsonResponse && jsonResponse.token) {
            await storeToken(jsonResponse.token);
            if (jsonResponse.username) {
                await storeUsername(jsonResponse.username);
            }
            if (jsonResponse.userData) {
                const userData = jsonResponse.userData;
                if (userData.name) {
                    await storeName(userData.name);
                } else if (userData.email) {
                    await storeName(userData.email);
                }
            }
        }
    }

    async postRequest({
        formData,
        endpoint,
        waitSeconds = 30,
        fileAsBytes,
        fileName,
        fileField,
        mediaType,
    }) {
        try {
            const uri = `${apiURL}/${endpoint}`;
            const headers = { 'Content-Type': 'multipart/form-data' };
            const data = new FormData();

            for (const key in formData) {
                if (formData[key] !== null) {
                    data.append(key, formData[key]);
                }
            }

            if (fileAsBytes) {
                data.append(fileField, new Blob([fileAsBytes], { type: mediaType }), fileName);
            }

            const response = await axios.post(uri, data, { headers, timeout: waitSeconds * 1000 });

            if (response.status !== 200) {
                throw new Error(response.data.error || response.data.errors || unhandledNetworkIssue);
            }

            await this.checkForToken(response.data);

            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw new axios.AxiosError({ code: 510, message: slowNetwork });
            }
            throw new axios.AxiosError({ code: error.response?.status || 510, message: (error.response?.data || error.message || unhandledNetworkIssue) });
        }
    }

    async recoveryRequest({ email }) {
        if (!email) {
            throw new AxiosError({ code: 599, message: 'Не указан email' });
        }


        const monitoredKey = 'recoveryRequest';
        await this._genericApiCall(monitoredKey);

        const formData = {
            action: 'recoveryRequest',
            email,
            ak,
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async signUp({ email, rawPassword, rCode = '' }) {
        const monitoredKey = 'signUp';
        await this._genericApiCall(monitoredKey);

        const password = hashPassword(rawPassword, email);

        const formData = {
            action: 'signUp',
            rCode,
            password,
            email,
            ak,
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async signIn({ email, rawPassword }) {
        const monitoredKey = 'signIn';
        await this._genericApiCall(monitoredKey);

        const password = hashPassword(rawPassword, email);

        const formData = {
            action: 'signIn',
            password,
            email,
            ak,
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async verifyEmail({ email, recoveryToken }) {
        const monitoredKey = 'verifyEmail';
        await this._genericApiCall(monitoredKey);

        const formData = {
            action: 'verifyEmail',
            email,
            token: recoveryToken,
            ak,
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async passwordReset({ email, recoveryToken, rawPassword }) {
        const monitoredKey = 'passwordReset';
        await this._genericApiCall(monitoredKey);

        const password = hashPassword(rawPassword, email);

        const formData = {
            action: 'passwordReset',
            token: recoveryToken,
            password,
            email,
            ak,
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async logout({ email, jwt }) {
        const monitoredKey = 'logout';
        await this._genericApiCall(monitoredKey);

        const formData = {
            token: jwt,
            ak,
            action: 'out',
        };

        return await this.postRequest({ formData, endpoint: Endpoints.auth });
    }

    async searchEntities({ query }) {
        const monitoredKey = `searchEntities:${query}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            query,
            jwt,
            email,
            ak,
        };

        const jsonResponse = await this.postRequest({ formData, endpoint: Endpoints.search });
        const entitiesAlive = [];
        const entitiesLiquidated = [];
        const entitiesParsed = jsonResponse.entities;

        for (const element of entitiesParsed) {
            const type = {
                'LEGAL': 'corporation',
                'INDIVIDUAL': 'businessIndividual',
            }[element.type] || 'person';

            const status = {
                'active': 'active',
                'liquidating': 'liquidating',
                'liquidated': 'liquidated',
                'bankrupt': 'bankrupt',
                'в отношении юридического лица в деле о несостоятельности (банкротстве) введено наблюдение': 'bankrupt',
                'юридическое лицо признано несостоятельным (банкротом) и в отношении него открыто конкурсное производство': 'bankrupt',
                'в отношении юридического лица в деле о несостоятельности (банкротстве) введено внешнее управление': 'bankrupt',
                'reorganizing': 'reorganizing',
                'действующий': 'active',
                'действующее': 'active',
                'действует': 'active',
                'не действует': 'liquidated',
                'недействующий': 'liquidated',
            }[element.state?.status?.toLowerCase()] || 'other';

            const registrationDate = element.state?.registration_date ? new Date(element.state.registration_date) : null;
            const liquidationDate = element.state?.liquidation_date ? new Date(element.state.liquidation_date) : null;

            const thisEntity = new Entity({
                searchKeyCEO: element.searchKeyCEO ?? false,
                searchKeyShareholder: element.searchKeyShareholder ?? false,
                type,
                name: element.value ?? 'не указано',
                activityName: element.okved_name ?? '',
                activityCode: `${element.okved}${element.okved_type === '2001' ? '-2001' : ''}`,
                address: element.address ?? 'сведения отсутствуют',
                taxNumber: element.INN ?? 'сведения отсутствуют',
                taxReason: type === 'corporation' ? element.KPP ?? '' : null,
                stateNumber: element.OGRN ?? 'сведения отсутствуют',
                status,
                registrationDate,
                liquidationDate,
                ceo: type === 'corporation' ? (element.management?.name ?? 'сведения отсутствуют') : null,
                ceoTitle: type === 'corporation' ? (element.management?.post ?? 'сведения отсутствуют') : null,
                shareholders: null,
                whereFound: element.whereFound ?? element.where_found ?? null,
            });

            if (status !== 'liquidated') {
                entitiesAlive.push(thisEntity);
            } else {
                entitiesLiquidated.push(thisEntity);
            }
        }

        entitiesLiquidated.sort((a, b) => (b.liquidationDate || new Date(2000)) - (a.liquidationDate || new Date(2000)));

        const entities = [...entitiesAlive, ...entitiesLiquidated];

        let personData;
        if (jsonResponse.personData) {
            personData = PersonData.fromJson(jsonResponse.personData);
        }

        return { entities, personData };
    }

    async detailedCorp({ ogrn, full = false }) {
        const monitoredKey = `detailedCorp:${ogrn}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ogrn,
            jwt,
            email,
            ak,
            full: !(!(full)),
        };

        const jsonResponse = await this.postRequest({ formData, endpoint: Endpoints.detailedCorp, waitSeconds: 90 });

        if (!jsonResponse.data) throw new Error(emptyResponse);

        const { common, error } = jsonResponse.data;

        if (!common && !error) throw new Error(emptyResponse);

        if (error) return { error, entity: null };

        let fetchedCorpJson;
        try {
            fetchedCorpJson = jsonResponse.data;
        } catch (e) {
            myDebugPrint(`error on parsing the fetched corp: ${e}`);
        }

        return { error: null, entity: fetchedCorpJson };
    }

    async cashUpload({ projectId = 'new', mode = 'replace', statements }) {
        if (!statements || statements.length == 0) {
            throw ('Файлы не выбраны');
        }
        const monitoredKey = `cashUpload:${projectId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId,
            mode,
            statements,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashAnalysis,
                waitSeconds: 90
            });
            return jsonResponse;
        } catch (e) {
            myDebugPrint(`jsonResponse: ${e}`);
        }
    }


    async cashGetSummary({ projectId, section }) {
        if (!projectId || projectId.length < 5) {
            throw ('Код проекта указан неверно');
        }
        const monitoredKey = `cashGetSummary:${projectId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId,
            section,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashGetProjectSummary,
                waitSeconds: 90
            });
            return jsonResponse;
        } catch (e) {
            myDebugPrint(`cashGetSummary error: ${e}`);
        }
    }

    async cashProjectsList({ page = 1 }) {
        const monitoredKey = `cashProjectsList:${page}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            page,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashProjectsList,
                waitSeconds: 90
            });
            return jsonResponse;
        } catch (e) {
            myDebugPrint(`cashProjectsList error: ${e}`);
        }
    }

    async cashEditProject({ projectId, goal, newName }) {
        if (!projectId || projectId.length < 5) {
            throw ('Код проекта указан неверно');
        }
        const monitoredKey = `cashEditProjectName:${projectId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId,
            goal,
            newName,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashEditProject,
                waitSeconds: 90
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw 'Аномальная ошибка. Обновите страницу'
        } catch (e) {
            myDebugPrint(`cashEditProject error: ${e}`);
        }
    }

    async cashGetPayments({ projectId, filterTypes, taxNumbers, currencyCode, page = 0, sorting }) {
        if (!projectId || projectId.length < 5) {
            throw ('Код проекта указан неверно');
        }
        const monitoredKey = `cashGetPayments:${projectId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId: projectId,
            currencyCode: currencyCode,
            page: page,
            sorting: sorting ? JSON.stringify(sorting) : null,
        };
        if (filterTypes) {
            formData.filterTypes = filterTypes.join('|')
        }
        if (taxNumbers) {
            formData.taxNumbers = taxNumbers.join('|')
        }

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashGetPayments,
                waitSeconds: 90
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw 'Аномальная ошибка. Обновите страницу'
        } catch (e) {
            myDebugPrint(`cashGetPayments error: ${e}`);
        }
    }


    async cashGetCounterparties({ projectId, direction, currencyCode, page = 0, needTotals = true }) {
        if (!projectId || projectId.length < 5) {
            throw ('Код проекта указан неверно');
        }
        const monitoredKey = `cashGetCounterparties:${projectId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId: projectId,
            direction: direction,
            currencyCode: currencyCode,
            page: page,
            needTotals: needTotals
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashGetCounterparties,
                waitSeconds: 90
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw 'Аномальная ошибка. Обновите страницу'
        } catch (e) {
            myDebugPrint(`cashGetCounterparties error: ${e}`);
        }
    }

    async cashEditPayment({ projectId, paymentId, transactionType }) {
        if (!projectId || projectId.length < 5) {
            throw ('Код проекта указан неверно');
        }
        if (!paymentId) {
            throw ('Код платежа указан неверно');
        }
        if (!transactionType) {
            throw ('Не указан тип транзакции');
        }
        const monitoredKey = `cashEditPayment:${projectId}${paymentId}`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';
        if (!jwt || !email) throw new Error(unauthorized);

        const formData = {
            ak,
            jwt,
            email,
            projectId: projectId,
            paymentId: paymentId,
            transactionType: transactionType,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.cashEditPayment,
                waitSeconds: 90
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw 'Аномальная ошибка. Обновите страницу'
        } catch (e) {
            myDebugPrint(`cashEditPayment error: ${e}`);
        }
    }


    async sendRequest({ email, phone, name, message }) {
        const monitoredKey = `sendRequest`;
        await this._genericApiCall(monitoredKey);

        const formData = {
            ak,
            email: email,
            phone: phone,
            name: name,
            message: message,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.apply,
                waitSeconds: 30
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw 'Аномальная ошибка. Обновите страницу'
        } catch (e) {
            myDebugPrint(`sendRequest error: ${e}`);
        }
    }


    async myPlan({ typeOfData = null, page = null }) {
        const monitoredKey = `myPlan`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';

        const formData = {
            ak,
            jwt,
            email,
            typeOfData: typeOfData,
            page: page,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.myPlan,
                waitSeconds: 30
            });
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw new Error('Аномальная ошибка. Обновите страницу')
        } catch (e) {
            myDebugPrint(`sendRequest error: ${e}`);
        }
    }

    async getMyHistory({ typeOfData = null, page = null }) {
        const monitoredKey = `myHistory`;
        await this._genericApiCall(monitoredKey);

        const jwt = getToken() ?? '';
        const email = getEmail() ?? '';

        const formData = {
            ak,
            jwt,
            email,
            typeOfData: typeOfData,
            page: page,
        };

        try {
            const jsonResponse = await this.postRequest({
                formData,
                endpoint: Endpoints.myHistory,
                waitSeconds: 30
            });
            myDebugPrint(`jsonResponse is ${JSON.stringify(jsonResponse)}`)
            if (jsonResponse && jsonResponse['data']) {
                return (jsonResponse['data']);
            }
            throw new Error('Аномальная ошибка. Обновите страницу')
        } catch (e) {
            myDebugPrint(`sendRequest error: ${e}`);
        }
    }
}

export default new ApiManager();
