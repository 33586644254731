import React from 'react';
import { Grid, Card, CardContent, CardHeader, Button, ThemeProvider, IconButton, Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import DoneAll from '@mui/icons-material/DoneAll';
import ToggleIconButton from './ToggleIconButton';

const Slicer = ({ title, items, selectedItems, onToggle, onSelectAll, onDeselectAll, order = false, columnsOnMD = 2, columnsOnXs = 2 }) => {
    const allSelected = items.length === selectedItems.length;

    const slicerTheme = createTheme({
        components: {
            MuiIconButton: {
                variants: [
                    {
                        props: { variant: 'slicerOn' },
                        style: {
                            backgroundColor: '#d0e2cd',
                        },
                    },
                    {
                        props: { variant: 'slicerOff' },
                        style: {
                            backgroundColor: '#fff',
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        borderWidth: '2px',
                        border: '#d0e2cd',
                        borderStyle: 'solid',
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'slicerOn' },
                        style: {
                            backgroundColor: '#d0e2cd',
                            width: '100%',
                            borderRadius: '5px',
                            borderWidth: '2px',
                            border: '#d0e2cd',
                            borderStyle: 'solid',
                            fontSize: 'x-small',
                            justifyContent: 'left',
                            textWrap: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'flow',
                            overflow: 'hidden',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        },
                    },
                    {
                        props: { variant: 'slicerOff' },
                        style: {
                            backgroundColor: '#fff',
                            width: '100%',
                            borderRadius: '5px',
                            borderWidth: '2px',
                            border: '#d0e2cd',
                            borderStyle: 'solid',
                            fontSize: 'x-small',
                            justifyContent: 'left',
                            textWrap: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'flow',
                            overflow: 'hidden',
                            textAlign: 'left',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                        },
                    },
                ],
            },
            MuiCard: {
                variants: [
                    {
                        props: { variant: 'slicer' },
                        style: {
                            width: '100%',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            borderWidth: '2px',
                            border: '#8a4b9a',
                            borderStyle: 'solid',
                            marginBottom: '25px',
                        },
                    },
                ],
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        paddingBottom: 0,
                    },
                    title: {
                        fontSize: 'medium', // Custom styles for the content
                    },
                },
            },
        },
    });

    function sortedItems() { return (order ? items.sort() : items) };

    return (
        <ThemeProvider theme={slicerTheme}>
            <Card style={{ width: '100%' }} variant='slicer'>
                <CardHeader
                    variant='slicer'
                    title={title}
                    action={
                        <ToggleIconButton
                            icon={<DoneAll />}
                            isOn={items.length == selectedItems.length}
                            onSelectAll={onSelectAll}
                            onDeselectAll={onDeselectAll}
                        />
                    }
                />
                <CardContent>
                    <Grid container spacing={0.2}>
                        {sortedItems().map((item) => (
                            <Grid item xs={12 / columnsOnXs} md={12 / columnsOnXs} key={item}>
                                <Tooltip title={item} arrow>
                                    <Button
                                        variant={selectedItems.includes(item) ? 'slicerOn' : 'slicerOff'}
                                        onClick={() => onToggle(item)}
                                        fullWidth
                                    >
                                        {item}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};

export default Slicer;
