import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Box, Divider } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business'; // Example icon
import { styled } from '@mui/material/styles';
import { createSearchParams, useNavigate } from "react-router-dom";
import { myDebugPrint } from '../utils/debug';

const StatusIndicator = styled('div')(({ theme, status }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: status === 'active' ? theme.palette.success.main : theme.palette.error.main,
}));

const BusinessCard = ({ entity, showCEO, showShareholders, newtab }) => {
    const navigate = useNavigate();

    const handleNavToCompany = async (ogrn) => {
        navigate({
            pathname: `/${entity.stateNumber}`,
            // search: createSearchParams({
            //     q: query
            // }).toString(),
        });
    };

    myDebugPrint(`entity is: ${JSON.stringify(entity)}`)

    return (
        <Box sx={{ maxWidth: '100%', width: 10000, mb: 2 }}>
            <Card elevation={2}>
                <CardContent>
                    <Box display="flex" alignItems="flex-start">
                        <Box flexGrow={1}>
                            <Typography variant="h6" color="primary" >
                                <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }} href={`/${entity.stateNumber}`} target={newtab ? '_blank' : '_self'} >
                                    {entity.type === 'corporation' && entity.searchKeyCEO ? 'Руководитель в ' : ''}
                                    {entity.name}
                                </a>
                            </Typography>
                            {entity.activityName && (
                                <Typography variant="body2" color="textSecondary">
                                    {entity.activityName}
                                </Typography>
                            )}
                            <Typography variant="body2">{entity.getTaxStateCodes()}</Typography>
                            <Typography variant="body2">Юр.адрес: {entity.address}</Typography>
                            {entity.registrationDate && (
                                <Typography variant="body2">Образовано: {_formatDate(entity.registrationDate)}</Typography>
                            )}
                            {entity.liquidationDate && (
                                <Typography variant="body2">Ликвидировано: {_formatDate(entity.liquidationDate)}</Typography>
                            )}
                            {entity.ceo && showCEO && <Typography variant="body2">Руководитель: {entity.ceo}</Typography>}
                            {!entity.ceo && showCEO && entity.ceos && entity.ceos.length > 0 &&
                                <Typography variant="body2">Руководитель: {entity.ceos[0].name}</Typography>
                            }
                            {entity.shareholders && showShareholders && (
                                <Typography variant="body2">Участники: {entity.shareholders.join('; ')}</Typography>
                            )}
                            {entity.whereFound &&
                                <Box>
                                    <br />
                                    <Divider />
                                    <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                                        Где найдено соответствие: {entity.whereFound}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                        <Box ml={2}>
                            <StatusIndicator status={entity.status} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

const _formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
};

export default BusinessCard;
