import React, { useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Typography } from '@mui/material';
import { formatDateToGivenFormat, parseDate } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);


const StatementDatesChart = ({ data, earliestDate }) => {
    // myDebugPrint(`data: ${JSON.stringify(data)}`);
    // Transform the data
    const transformedData = data.map((item, index) => {
        const dateStart = parseDate({ dateString: item.DateStart, format: 'dd.MM.yyyy' });
        const dateEnd = parseDate({ dateString: item.DateEnd, format: 'dd.MM.yyyy' });
        return {
            x: [dateStart, dateEnd],
            y: index + 1,
            dateStart: formatDateToGivenFormat({ date: dateStart, format: 'MMM yyyy' }),
            dateEnd: formatDateToGivenFormat({ date: dateEnd, format: 'MMM yyyy' }),
            dateStartDB: item.DateStart,
            dateEndDB: item.DateEnd,
        };
    });

    // Extract dates for labels
    const dateLabels = Array.from(new Set(transformedData.flatMap(item => [item.dateStart, item.dateEnd])));
    const minDate = Math.min(...transformedData.flatMap(item => item.x));
    const maxDate = Math.max(...transformedData.flatMap(item => item.x));

    const chartData = {
        labels: dateLabels,
        datasets: [
            {
                label: 'Duration',
                data: transformedData.map(item => ({
                    x: item.x.map(date => date.getTime()),
                    y: item.y
                })),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                borderSkipped: false,
                barPercentage: 1,
                categoryPercentage: 1,
            }
        ]
    };

    let today = new Date();


    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                    tooltipFormat: 'MMM yyyy',
                    displayFormats: {
                        month: 'dd-MM-yyyy'
                    }
                },
                min: earliestDate ?? minDate,
                max: new Date(today.getFullYear(), today.getMonth() + 1, 1),
                title: {
                    display: false,
                }
            },
            y: {
                type: 'linear',
                beginAtZero: false,
                title: {
                    display: false,
                },
                min: data.length == 1 ? 0 : 1,
                max: Math.max(2, data.length),
                ticks: {
                    stepSize: 1,
                    display: false,
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const item = transformedData[context.dataIndex];
                        return ` ${item.dateStartDB} - ${item.dateEndDB}`;
                    }
                }
            }
        }
    };


    // const chartRef = useRef(null);

    // useEffect(() => {
    //     const chartInstance = chartRef.current;

    //     return () => {
    //         if (chartInstance) {
    //             chartInstance.destroy();
    //         }
    //     };
    // }, []);

    return (
        <Box style={{ marginTop: '35px', width: '100%', height: `${30.0 + 30.0 * data.length}px` }}>
            <Typography variant='body2'>
                Периоды, за которые предоставлены выписки
            </Typography>
            <Bar data={chartData} options={options} />
        </Box >
    );
};

export default StatementDatesChart;
