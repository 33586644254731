import React, { useState } from 'react';
import {
    CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Typography, Box, TablePagination,
    Grid, useMediaQuery,
    Tooltip,
    Divider,
} from '@mui/material';
import CashCardTemplate from './CashCardTemplate';
import { formatDoubleToPaddedString, parseAndFormatDate } from '../../../utils/utils';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import { FilterAlt, TableBarTwoTone, TableViewTwoTone } from '@mui/icons-material';
import MyFilterButton from '../../common/MyFilterButton';
import { myDebugPrint } from '../../../utils/debug';
import PaymentsTable from './PaymentsTable';
import CounterpartyLinks from '../elements/CounterpartyLinks';

const validateNumber = (value, roundDigits = 0) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const slicerItems = [
    'Недостоверные данные в ЕГРЮЛ',
    'Массовый адрес',
    'Идет банкротство',
    'Нет явных признаков',
    'Встречные обороты >10%',
    'ДДС сопоставимы с выручкой контрагента',
];
const slicerItems2 = [
    'Ликвидировано',
    'Действующее',
    'Не найдено в ЕГРЮЛ/ЕГРИП',
];

const RisksMainData = ({ cashProject, updateCashProjectPayment, }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSlicer, setIsSlicer] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState(slicerItems);
    const [selectedFilters2, setSelectedFilters2] = useState(slicerItems2);
    const [selectedCompany, setSelectedCompany] = useState({ TaxNumber: null, ShortName: null });
    const [paymentsKey, setPaymentsKey] = useState(0); // Add a state to manage the key

    const togglePayments = ({ turnOn }) => {
        setPaymentsKey(prevKey => prevKey + 1); // Increment the key to force remount
    };

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const slicerGridSize = isSmallScreen ? 12 : 6;

    const toggleSlicer = () => setIsSlicer(!isSlicer);

    const toggleFilter = (filter) => {
        setSelectedFilters((prevFilters) =>
            prevFilters.includes(filter)
                ? prevFilters.filter((f) => f !== filter)
                : [...prevFilters, filter]
        );
    };

    const toggleFilter2 = (filter) => {
        setSelectedFilters2((prevFilters) =>
            prevFilters.includes(filter)
                ? prevFilters.filter((f) => f !== filter)
                : [...prevFilters, filter]
        );
    };

    const selectAllFilters = () => {
        setSelectedFilters(slicerItems);
    };

    const deselectAllFilters = () => {
        setSelectedFilters([]);
    };
    const selectAllFilters2 = () => {
        setSelectedFilters2(slicerItems2);
    };

    const deselectAllFilters2 = () => {
        setSelectedFilters2([]);
    };

    const applyFilters = (entity) => {
        const filter1Match = (
            selectedFilters.includes('Недостоверные данные в ЕГРЮЛ') && (entity.InvalidityScore > 0 || entity.BadStatusScore > 0)
        ) || (
                selectedFilters.includes('Массовый адрес') && entity.MassAddressScore > 0
            ) || (
                selectedFilters.includes('Идет банкротство') && entity.BankruptcyScore > 0
            ) || (
                selectedFilters.includes('Встречные обороты >10%') && (Math.min(entity.inflows, entity.outflows) / Math.max(entity.inflows, entity.outflows) > 0.1)
            ) || (
                selectedFilters.includes('ДДС сопоставимы с выручкой контрагента') && entity.Revenue && (Math.max(entity.inflows, entity.outflows) / (entity.Revenue * 1000) > 0.6)
            ) || (
                selectedFilters.includes('Нет явных признаков')
                && !(entity.InvalidityScore > 0)
                && !(entity.BadStatusScore > 0)
                && !(entity.MassAddressScore > 0)
                && !(entity.BankruptcyScore > 0)
            );

        const filter2Match = (
            selectedFilters2.includes('Ликвидировано') && entity.LiquidationScore > 0
        ) || (
                selectedFilters2.includes('Действующее') && (!entity.LiquidationScore || entity.LiquidationScore === 0)
            ) || (
                selectedFilters2.includes('Не найдено в ЕГРЮЛ/ЕГРИП') && (!entity.OGRN && !entity.ShortName)
            );

        const companyFilterMatch = (
            selectedCompany.TaxNumber && (entity.CounterpartyINN == selectedCompany.TaxNumber)
        ) || !selectedCompany.TaxNumber;

        return filter1Match && filter2Match && companyFilterMatch;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    const filteredRisks = cashProject.risks.filter(applyFilters);

    const filterCompany = ({ TaxNumber, ShortName }) => {
        myDebugPrint(`filterCompany: TaxNumber, ShortName: ${TaxNumber}, ${ShortName}`)
        if (!TaxNumber || selectedCompany.TaxNumber === TaxNumber) {
            setSelectedCompany({ 'TaxNumber': null, 'ShortName': null })
            return;
        }
        setSelectedCompany({ 'TaxNumber': TaxNumber, 'ShortName': ShortName })
        myDebugPrint(`filterCompany: selected company: ${JSON.stringify(selectedCompany)}`)
        return;
    }

    const renderTableRows = () => {

        const rows = [];
        const startIndex = page * rowsPerPage;
        const endIndex = Math.min(startIndex + rowsPerPage, filteredRisks.length);

        for (let i = startIndex; i < endIndex; i++) {
            // const entity = cashProject.risks[i];
            const entity = filteredRisks[i];
            rows.push(
                <TableRow variant={(i % 2 !== 0) ? "oddCashAnalysis" : "evenCashAnalysis"} key={`${entity.CounterpartyINN}`}>
                    <TableCell variant="cashAnalysis">
                        {entity.ShortName} (ИНН {entity.CounterpartyINN})
                        <span style={{ color: 'red' }}>
                            {((entity.InvalidityScore || entity.BadStatusScore)
                                && (entity.InvalidityScore > 0 || entity.BadStatusScore > 0)) ? <span><br />• Недостоверные данные в ЕГРЮЛ</span> : null}
                            {(entity.MassAddressScore && entity.MassAddressScore > 0) ? <span><br />• Массовый адрес</span> : null}
                            {(entity.BankruptcyScore && entity.BankruptcyScore > 0) ? <span><br />• Идет банкротство</span> : null}
                            {(entity.LiquidationScore && entity.LiquidationScore > 0) ? <span><br />• Ликвидировано</span> : null}
                            {(entity.inflows && entity.outflows && (Math.min(entity.inflows, entity.outflows) / Math.max(entity.inflows, entity.outflows)) > 0.1) ?
                                <span>
                                    <br />
                                    <Tooltip title="Как счиитаем: если получили больше, чем заплатили, то оплаты / поступления; если заплатили больше, то поступления / оплаты">
                                        • Встречные обороты &gt;10% &#65533;
                                    </Tooltip>
                                </span> : null}
                            {(entity.Revenue && (Math.max(entity.inflows, entity.outflows) / (entity.Revenue * 1000) > 0.6)) ?
                                <span>
                                    <br />
                                    <Tooltip title="При сопоставлении ДДС с выручкой контрагента сранивается больший поток
                    (платежи или постуления) за все время анализа против выручки контрагента за последний
                    доступный год. Важные моменты: выручка может быть без НДС,
                    а денежный поток с НДС. Выручка и денежные потоки могли по-разному меняться во времени.">
                                        • ДДС сопоставимы с выручкой контрагента &#65533;
                                    </Tooltip>
                                </span> : null}
                        </span>
                        <CounterpartyLinks
                            filterCompany={filterCompany}
                            entity={entity}
                        />
                        {/* <Divider />
                        <span style={{ color: '#1976d2', cursor: 'pointer' }} onClick={() => filterCompany({ TaxNumber: entity.CounterpartyINN, ShortName: entity.ShortName })}>
                            Платежи
                        </span>
                        &nbsp;|&nbsp;
                        <span>
                            {entity.OGRN &&
                                <a
                                    href={entity.OGRN ? `/${entity.OGRN}` : `/search?q=${entity.CounterpartyINN}`}
                                    target='_blank'
                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                >
                                    Карточка
                                </a>
                            }
                        </span> */}
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {entity.StatusName}
                        {entity.DateLiquidation ? ' ' + parseAndFormatDate({ dateString: entity.DateLiquidation, sourceFormat: 'yyyy-MM-dd' }) : null}
                    </TableCell>
                    <TableCell variant="cashAnalysis">{validateNumber(entity.outflows / 1000)}</TableCell>
                    <TableCell variant="cashAnalysis">{validateNumber(entity.inflows / 1000)}</TableCell>
                    <TableCell variant="cashAnalysis">
                        {!entity.Revenue
                            ? <span>Нет данных</span>
                            : <span>{validateNumber(entity.Revenue)} в {entity.RevenueYear}</span>
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {!entity.Headcount
                            ? <span>Нет данных</span>
                            : <span>{entity.Headcount} на {parseAndFormatDate({ dateString: entity.HeadcountDate, sourceFormat: 'yyyy-MM-dd' })}</span>
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">{entity.ActualAddress}</TableCell>
                    <TableCell variant="cashAnalysis">{entity.OkvedName}</TableCell>
                    <TableCell variant="cashAnalysis">
                        {
                            (entity.CEOPositionName)
                                ? entity.CEOPositionName + ': '
                                : null
                        }
                        {
                            (entity.CEOFullName)
                                ? entity.CEOFullName + ' '
                                : null
                        }
                        {
                            (entity.CEOTaxNumber)
                                ? entity.CEOTaxNumber + ' '
                                : null
                        }
                    </TableCell>
                    <TableCell variant="cashAnalysis">
                        {entity.MassAddressRegister ? <p>Адрес массовой регистрации</p> : null}
                        {entity.InvalidAddressText ? <p>{entity.InvalidAddressText}</p> : null}
                        {entity.InvalidCEOInfoText ? <p>{entity.InvalidCEOInfoText}</p> : null}
                        {entity.CEODisqualificationEndDate ? <p>Дисквалификация руководителя до&nbsp;{parseAndFormatDate({ dateString: entity.CEODisqualificationEndDate, sourceFormat: 'yyyy-MM-dd' })}</p> : null}
                        {entity.InvalidFounderInfoText ? <p>{entity.InvalidFounderInfoText}</p> : null}
                    </TableCell>
                </TableRow>
            );
        }

        return rows;
    };

    const tableContent = (
        <CardContent>
            {isSlicer &&
                <Grid container spacing={2}>
                    <Grid item xs={6} md={slicerGridSize / 2} key="slicer-indicators">
                        <Slicer
                            title="Признаки риска"
                            items={slicerItems}
                            selectedItems={selectedFilters}
                            onToggle={toggleFilter}
                            onSelectAll={selectAllFilters}
                            onDeselectAll={deselectAllFilters}
                            order={true}
                            columnsOnXs={1}
                            columnsOnMD={1}
                        />
                    </Grid>
                    <Grid item xs={6} md={slicerGridSize / 2} key="slicer-status" >
                        <Slicer
                            title="Статус"
                            items={slicerItems2}
                            selectedItems={selectedFilters2}
                            onToggle={toggleFilter2}
                            onSelectAll={selectAllFilters2}
                            onDeselectAll={deselectAllFilters2}
                            order={true}
                            columnsOnXs={1}
                            columnsOnMD={1}
                        />
                    </Grid>
                    {selectedCompany && selectedCompany.TaxNumber &&
                        <Grid item xs={6} md={slicerGridSize / 2} key="filter-company">
                            <Slicer
                                title="Контрагент"
                                items={[
                                    (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                    + (`ИНН ${selectedCompany.TaxNumber}`)
                                    + (selectedCompany.ShortName ? ')' : '')
                                ]}
                                selectedItems={[
                                    (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                    + (`ИНН ${selectedCompany.TaxNumber}`)
                                    + (selectedCompany.ShortName ? ')' : '')
                                ]}
                                onToggle={() => filterCompany({ TaxNumber: null })}
                                onSelectAll={() => filterCompany({ TaxNumber: null })}
                                onDeselectAll={() => filterCompany({ TaxNumber: null })}
                                order={true}
                                columnsOnXs={1}
                                columnsOnMD={1}
                            />
                        </Grid>
                    }
                </Grid>
            }
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Контрагент</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Статус на дату проверки</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Контрагенту перечислено (тыс.руб.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>От контрагента получено (тыс.руб.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Выручка контрагента (тыс.руб.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Численность персонала (чел.)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Адрес контрагента</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Основной ОКВЭД</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Руководитель</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center', minWidth: '250px', }}>Особые отметки</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    labelRowsPerPage='Компаний на странице'
                    component="div"
                    count={filteredRisks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            {selectedCompany && selectedCompany.TaxNumber &&
                <Box>
                    <br />
                    <Divider />
                    <br />
                    <PaymentsTable
                        key={paymentsKey} // Add the key prop
                        projectId={cashProject.projectId}
                        taxNumbers={[selectedCompany.TaxNumber]}
                        currencyCode={'810'}
                        recordsPerPage={10}
                        updateCashProjectPayment={updateCashProjectPayment}
                    />
                </Box>
            }
        </CardContent>
    );

    return (
        <CashCardTemplate
            title={<Box>
                <span>
                    Анализ рисков по контрагентам
                </span>
            </Box>}
            subheader="Ключевые данные по основным контрагентам предпринимателям и юр.лицам"
            action={
                <ToggleIconButton
                    icon={<FilterAlt />}
                    isOn={isSlicer}
                    onSelectAll={toggleSlicer}
                    onDeselectAll={toggleSlicer}
                />
            }
            cardcontent={tableContent}
            initalFolded={false}
        />
    );
};

export default RisksMainData;
