import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Divider } from '@mui/material';
import CircleLoader from 'react-spinners/CircleLoader';
import Apimanager from '../../utils/api';
import '../dashboard/styles/Dashboard.css';
import { formatDoubleToPaddedString } from '../../utils/utils';
import MyFileUpload from '../forms/MyFileUpload';
import { myDebugPrint } from '../../utils/debug';
import CashProjectClass from '../../classes/cash/CashProjectClass';
import BusinessDetailsSkeleton from '../dashboard/BusinessDetailsSkeleton';
import { useSnackbar } from '../../context/SnackbarContext';
import StatementsProcessingResults from './StatementsProcessingResults';
import ReportCashData from './ReportCashData';
import SectionsNavigation, { sectionsCash } from '../common/SectionsNavigation';
import { useLocation } from 'react-router-dom';
import ReportCashRisk from './ReportCashRisk';

const ReportCashProject = ({ projectId, handleError, cashUploader }) => {
    const [cashProjectClass, setCashProjectClass] = useState();
    const [isFetching, setIsFetching] = useState(true);
    const [fetchingError, setFetchingError] = useState();
    const [projectNameEditIsFetching, setProjectNameEditIsFetching] = useState(false);
    const showSnackbar = useSnackbar();
    const [forceUpdateNameAtChild, setForceUpdateNameAtChild] = useState();
    const [fetchingReanalyzer, setFetchingReanalyzer] = useState(false);
    const location = useLocation();

    // Helper function to get the value of the 's' URL parameter
    const getSectionFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get('s');
    };

    // Determine the current section
    const currentSection = getSectionFromUrl() || 'common';

    const handleFetchCashProjectClass = async (signal) => {
        setCashProjectClass(null);
        setIsFetching(true);
        setFetchingError('');
        try {
            const response = await Apimanager.cashGetSummary({ projectId: projectId, section: currentSection });
            if (!signal || !signal.aborted) {
                const fetchedProjectSummary = response['data'];
                const pSummary = CashProjectClass.fromJson(fetchedProjectSummary);
                setCashProjectClass(pSummary);
            }
        } catch (e) {
            if (!signal || !signal.aborted) {
                myDebugPrint(`handleSubmit error: ${e}, ${JSON.stringify(e)}`);
                setFetchingError('Поиск отменен. Чтобы открыть нужные данные, обновите страницу.')
                handleError({ errorMessage: 'Ошибка при выполнении поиска. Обновите страницу через минуту. Связь с поддержкой info@brpadvice.ru' });
            }
        } finally {
            if (!signal || !signal.aborted) {
                setIsFetching(false);
            }
        }
    };

    function updateCashProjectPayment({ oldTransactionType, newTransactionType, transactionDate, paymentAmountWithSign, currencyCode }) {
        if (oldTransactionType === newTransactionType) return;


        const editMonth = transactionDate.getMonth() + 1;
        const editYear = transactionDate.getFullYear();
        let prevTypesSummary = [...cashProjectClass.transactiontype]
        myDebugPrint(`editMonth:${editMonth}, editYear:${editYear}`)
        myDebugPrint(`ok, BEFORE updating a prevTypesSummary it is: ${JSON.stringify(prevTypesSummary)}`)
        let existed = false;
        const updatedTypesSummary = prevTypesSummary.map(item => {
            if (item.currencyCode === currencyCode && item.TransactionMonth === editMonth && item.TransactionYear === editYear
                && (item.TransactionTypes === oldTransactionType || item.TransactionTypes === newTransactionType)) {
                if (item.TransactionTypes === newTransactionType) { existed = true };
                item.Netflows = item.Netflows + (item.TransactionTypes === oldTransactionType ? -1 : 1) * paymentAmountWithSign;
                myDebugPrint(`transactions found... updating...`)
                if (paymentAmountWithSign > 0) {
                    item.Inflows = item.Inflows + (item.TransactionTypes === oldTransactionType ? -1 : 1) * paymentAmountWithSign;
                } else {
                    item.Outflows = item.Outflows + (item.TransactionTypes === oldTransactionType ? -1 : 1) * (- paymentAmountWithSign);
                }
                return item;
            }
            return item;
        });
        if (!existed) {
            updatedTypesSummary.push({
                'TransactionYear': editYear,
                'TransactionQuarter': Math.ceil(editMonth / 3),
                'TransactionMonth': editMonth,
                'CurrencyCode': currencyCode,
                'TransactionTypes': newTransactionType,
                'Inflows': Math.max(0, paymentAmountWithSign),
                'Outflows': -Math.min(0, paymentAmountWithSign),
                'Errorflows': 0,
                'Netflows': paymentAmountWithSign,
            })
        }
        myDebugPrint(`ok, AFTER updating a prevTypesSummary it is now: ${JSON.stringify(updatedTypesSummary)}`);

        const updatedCashProjectClass = {
            ...cashProjectClass,
            transactiontype: updatedTypesSummary,
        };

        setCashProjectClass(updatedCashProjectClass);
    }

    useEffect(() => {
        const controller = new AbortController();
        handleFetchCashProjectClass(controller.signal);

        return () => {
            controller.abort();
        };
    }, [projectId]);

    useEffect(() => {

    }, [(cashProjectClass && cashProjectClass.projectName)])

    const reanalyzePayments = async (type) => {
        let goal;
        if (type === 'reanalyze') {
            goal = 'reanalyzePayments';
        } else if (type === 'retotal') {
            goal = 'rebuildTotals';
        } else {
            showSnackbar('Не указан тип обновления проекта', 'error')
            return;
        }
        setFetchingReanalyzer(true)
        let done = false
        try {
            const response = await Apimanager.cashEditProject({
                projectId: projectId,
                goal: goal
            });
            if (response && response['status']) {
                showSnackbar('Анализ начался и займет несколько минут', 'success')
                done = true
            } else {
                showSnackbar('Не удалось получить статус проекта. Обновитие страницу, чтобы узнать актуальный статус', 'error')
            }
        } catch (e) {
            myDebugPrint(`reanalyzePayments error: ${e}`);
            showSnackbar('Не удалось получить статус проекта. Обновитие страницу, чтобы узнать актуальный статус', 'error')
        } finally {
            if (done) {
                handleFetchCashProjectClass();
            }
            setFetchingReanalyzer(false)
        }
    }


    const updateProjectName = async (newNameUntriimed) => {
        const newName = newNameUntriimed.trim();
        myDebugPrint(`updateProjectName: ${newName}`);
        if (!newName || newName.length < 3) {
            handleError({ errorMessage: 'Название проекта должно содержать не менее 3 символов' })
            return;
        }
        setProjectNameEditIsFetching(true);
        try {
            const response = await Apimanager.cashEditProject({
                projectId: projectId,
                goal: 'projectName',
                newName: newName
            });
            if (!response || !response['projectId']) {
                showSnackbar('Не удалось сохранить название проекта ', 'error');
            } else if (response['projectId'] == projectId) {
                if (response['projectName'] == newName) {
                    showSnackbar('Готово', 'success');
                    cashProjectClass.projectName = newName;
                } else {
                    showSnackbar(`Не удалось сохранить название проекта ${newName}`, 'error');
                }
                setForceUpdateNameAtChild(!forceUpdateNameAtChild);
            } else {
                if (response['projectName'] == newName) {
                    showSnackbar(`Готово! Название проекта ${newName} обновлено`, 'success');
                } else {
                    showSnackbar(`Не удалось сохранить название проекта ${newName}`, 'error');
                }
            }
        } catch (e) {
            myDebugPrint(`updateProjectName error: ${e}`);
            showSnackbar(`Не удалось сохранить название проекта ${newName}`, 'error');
        } finally {
            setProjectNameEditIsFetching(false);
        }
    }

    return (
        <Box>
            {isFetching &&
                <Typography variant="body2">
                    Загружаем данные по проекту и подсчитываем итоги по движениям денежных средств...
                </Typography>
            }
            {cashProjectClass &&
                <BusinessDetailsSkeleton
                    entityName={cashProjectClass.projectName}
                    projectNameEditFunction={updateProjectName}
                    projectNameEditIsFetching={projectNameEditIsFetching}
                    forceEditNameState={forceUpdateNameAtChild}
                    widgetsBeforeTitle={
                        cashProjectClass.processingStatus === 'done' &&
                        [<SectionsNavigation projectId={projectId} currentSection={currentSection} />]
                    }
                    h1={sectionsCash[currentSection].h1}
                    mainContent={[
                        currentSection === 'common' &&
                        <ReportCashData
                            cashProject={cashProjectClass}
                            cashUploader={cashUploader}
                            fetchingReanalyzer={fetchingReanalyzer}
                            reanalyzePayments={reanalyzePayments}
                            refetch={handleFetchCashProjectClass}
                            updateCashProjectPayment={updateCashProjectPayment}

                        />,
                        currentSection === 'risk' &&
                        <ReportCashRisk
                            cashProject={cashProjectClass}
                            updateCashProjectPayment={updateCashProjectPayment}
                        />

                    ]}
                >

                </BusinessDetailsSkeleton>
            }

        </Box >
    );
}

export default ReportCashProject;