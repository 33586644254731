import React, { useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
// import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import './styles/Dashboard.css';
import { cleanAuth, getEmail } from '../../utils/storage';
import CircleLoader from "react-spinners/CircleLoader";

import InputBase from '@mui/material/InputBase';

import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';

import { createSearchParams, useNavigate } from "react-router-dom";
import corpDrawerTaps from '../../classes/CorpDrawerTaps';
import { myDebugPrint } from '../../utils/debug';
import { Tooltip, Typography } from '@mui/material';
import CorpDrawer from './menus/CorpDrawer';
import { useAuth } from '../../context/AuthContext';
import StyledInputBase from '../forms/StyledInputBase';
import { Biotech, CreateNewFolder, FolderCopy, ManageAccounts, Troubleshoot } from '@mui/icons-material';



const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    maxWidth: 700,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 45,
    [theme.breakpoints.up('md')]: {
        width: 125,
    },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const SwipeableDrawer = styled(MuiSwipeableDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function DashboardSkeleton({ initialQuery, isSearching, forceSearch, isCorp, companyName, activeSection, onSectionChange, children }) {
    const { isAuthenticated, logout } = useAuth();

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState(initialQuery);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (!query || query.length < 2) { return false; }

        if (initialQuery != query) {
            navigate({
                pathname: "/search",
                search: createSearchParams({
                    q: query
                }).toString(),
            });
        } else {
            forceSearch();
        }
    };

    useEffect(() => {
        setQuery(initialQuery);
    }, [initialQuery]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleLogout = () => {
        cleanAuth();
        logout();
        navigate('/');
    }

    const handleInternalLink = (({ goal, searchParams }) => {
        if (!searchParams) {
            navigate({
                pathname: `/${goal}`,
            })
        } else {
            navigate({
                pathname: `/${goal}`,
                search: createSearchParams(searchParams).toString(),
            });
        }
    })

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* <CssBaseline /> */}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <form id='searchEntities' name='searchEntities' onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    </form>
                    <Search>
                        <SearchIconWrapper>
                            {isSmallScreen
                                ? <SearchIcon />
                                : <img
                                    src="/assets/images/finradar - logo.png"
                                    alt="ФИНРАДАР"
                                    style={{ maxHeight: 25 }}
                                />
                            }
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Поиск компаний, ИП и физ.лиц"
                            inputProps={{ 'aria-label': 'search', form: "searchEntities" }}
                            // defaultValue={initialQuery}
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}
                            onSubmit={handleSubmit}
                            sx={{ width: '100%' }}
                        />
                    </Search>
                    {
                        query && query.length > 1 &&
                        (isSearching
                            ? <div style={{ marginLeft: 16, textAlign: 'center', }}>
                                <CircleLoader color="#8A4B9A" className='spinner' size={35} />
                            </div>
                            : <Button variant="contained" color="success" sx={{ ml: 2 }} onClick={() => handleSubmit()}>
                                Найти
                            </Button>
                        )
                    }
                </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', maxWidth: '100%', paddingTop: '90px', paddingRight: '60px' }}>
                    <SwipeableDrawer variant="permanent" open={open} onOpen={() => handleDrawerOpen} onClose={() => handleDrawerClose}>
                        <DrawerHeader>
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 'small' }}>
                                {getEmail()}
                            </div>
                        </DrawerHeader>
                        <Divider />
                        {isCorp &&
                            <CorpDrawer
                                open={open}
                                companyName={companyName}
                                activeSection={activeSection}
                                onSectionChange={onSectionChange}
                            />
                        }
                        {isCorp &&
                            <Divider />
                        }

                        {/* Here goes the BUSINESS CHECK NAVIGATION */}
                        <Typography
                            variant="body2" // Adjust the variant as needed
                            sx={{ fontSize: '1rem', marginLeft: '20px', marginTop: 1, textAlign: 'left' }} // This should affect the font size
                        >
                            {open ? 'Проверка контрагента' : "\u00A0"}
                        </Typography>
                        <List>
                            <ListItem key={'businessCheck'} disablePadding sx={{ display: 'block', }}>
                                <Tooltip title="Поиск компаний, ИП и физ.лиц">
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleInternalLink({ goal: 'search' })}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Troubleshoot />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Новый поиск
                                        </Typography>} sx={{
                                            opacity: open ? 1 : 0
                                        }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        </List>
                        <Divider />
                        {/* Here ends the BUSINESS CHECK NAVIGATION */}


                        {/* Here goes the CASH MANAGEMENT NAVIGATION */}
                        <Typography
                            variant="body2" // Adjust the variant as needed
                            sx={{ fontSize: '1rem', marginLeft: '20px', marginTop: 1, textAlign: 'left' }} // This should affect the font size
                        >
                            {open ? 'Анализ выписок' : "\u00A0"}
                        </Typography>
                        <List>
                            <ListItem key={'newCashProject'} disablePadding sx={{ display: 'block', }}>
                                <Tooltip title="Создайте новый анализ или вернитесь к готовому">
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleInternalLink({ goal: 'cash' })}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Biotech />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Центр управления
                                        </Typography>} sx={{
                                            opacity: open ? 1 : 0
                                        }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        </List>
                        <Divider />
                        {/* Here ends the CASH MANAGEMENT NAVIGATION */}



                        {open &&
                            <Typography
                                variant="body2" // Adjust the variant as needed
                                sx={{ fontSize: '1rem', marginLeft: '20px', marginTop: 1, textAlign: 'left' }} // This should affect the font size
                            >
                                {open ? 'Аккаунт' : "\u00A0"}
                            </Typography>
                        }
                        {open &&
                            <List>
                                <ListItem key={'myaccountbutton'} disablePadding sx={{ display: 'block', }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleInternalLink({ goal: 'myplan' })}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <ManageAccounts />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Мой аккаунт
                                        </Typography>} sx={{
                                            opacity: open ? 1 : 0
                                        }} />
                                    </ListItemButton>
                                </ListItem>                        <ListItem key={'logoutbutton'} disablePadding sx={{ display: 'block', }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => handleLogout()}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <LogoutOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '0.8rem',
                                            }}
                                        >
                                            Выйти из аккаунта
                                        </Typography>} sx={{
                                            opacity: open ? 1 : 0
                                        }} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        }


                    </SwipeableDrawer>
                    <DrawerHeader />
                    <div style={{
                        maxWidth: 'calc( 100% - 50px )'
                    }}>
                        <Box component="main" sx={{ maxWidth: '100%' }}>
                            {children}
                        </Box>
                    </div>
                </div >
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ backgroundColor: '#f0f0f0', padding: '20px', textAlign: 'center', mt: 'auto', mt: 5, pt: 5 }}>
                <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
                    © 2024 ФинРадар - ИИ-анализ и проверка контрагентов. Проект группы&nbsp;
                    <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                        href='https://brpadvice.ru/?utm_source=finradar'
                        target='_blank'
                    >
                        BRP ADVICE
                    </a>
                    <br />
                    <br />
                    Поддержка пользователей по email&nbsp;
                    <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                        href='mailto:info@brpadvice.ru'
                        rel='nofollow'
                    >
                        info@brpadvice.ru
                    </a>
                    &nbsp;и в Telegram:&nbsp;
                    <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                        href='https://t.me/brpadvice'
                        rel='nofollow'
                    >
                        @brpadvice
                    </a>
                </Typography>
            </Box>
        </Box >
    );
}