import React from 'react';
import { Box, Card, CardHeader, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import CashCardTemplate from './CashCardTemplate';

const MissingAccounts = ({ statementsSummary, collectedTaxNumbersAndAccounts }) => {
    // Helper function to extract all accounts from the StatementsSummary
    const extractAccountsFromStatements = (statementsSummary) => {
        const accountsInStatements = {};

        statementsSummary.forEach(statement => {
            const fileData = Object.values(statement)[0]; // get the array of account data

            fileData.forEach(data => {
                const inn = data.INN;
                const accNmbr = data.AccNmbr;

                if (!accountsInStatements[inn]) {
                    accountsInStatements[inn] = new Set();
                }
                accountsInStatements[inn].add(accNmbr);
            });
        });

        return accountsInStatements;
    };

    // Extracted accounts from the statements
    const accountsInStatements = extractAccountsFromStatements(statementsSummary);

    // Function to find missing accounts for each INN
    const findMissingAccounts = () => {
        const missingAccounts = [];

        collectedTaxNumbersAndAccounts.forEach(item => {
            const inn = Object.keys(item)[0];
            const accounts = item[inn];
            const accountsInSummary = accountsInStatements[inn] || new Set();

            const missing = accounts.filter(account => !accountsInSummary.has(account));

            if (missing.length > 0) {
                missingAccounts.push({ inn, missingAccounts: missing });
            }
        });

        return missingAccounts;
    };

    const missingAccountsList = findMissingAccounts();

    return (
        <CashCardTemplate
            title={<Box>
                <span>{(
                    missingAccountsList && missingAccountsList.length === 0
                        ? <span style={{ color: 'green', fontSize: 'larger' }}>✓</span>
                        : <span style={{ color: 'red', fontSize: 'larger' }}>⚠</span>
                )}
                    &nbsp;Счета, по которым могут отсутствовать сведения
                </span>
            </Box>
            }
            subheader="Сверка загруженных выписок с данными о счетах в таких выписках"
            initalFolded={missingAccountsList && missingAccountsList.length === 0}
            cardcontent={
                <CardContent>
                    {missingAccountsList.length === 0 ? (
                        <Typography variant="body1">
                            В выписках не найдены счета компании, которые не были загружены в сервис.
                            <br />
                            Это не означает,
                            <br />
                            а) что таких счетов нет;
                            <br />
                            б) что выписки охватывают все необходимые периоды анализа.
                            <br />
                            <br />
                            Для проверки полноты анализируемых данных может быть использована справка ФНС о счетах организации (ИП).
                        </Typography>
                    ) : (
                        missingAccountsList.map((item, index) => (
                            <Box key={index} mb={2}>
                                <Typography variant="body"><strong>ИНН: {item.inn}</strong></Typography>
                                {item.missingAccounts.map((account, i) => (
                                    <Typography variant="body" key={i}>
                                        <br />
                                        {`Счет №${account}`}
                                    </Typography>
                                ))}
                            </Box>
                        ))
                    )}
                </CardContent>
            }
        />
    );
};

export default MissingAccounts;
