import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Tabs, Tab, useMediaQuery, Divider } from '@mui/material';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import { formatMonth, shortenCashNumbers } from '../../../utils/utils';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import PaymentsTable from './PaymentsTable';
import { myDebugPrint } from '../../../utils/debug';
import CashCardTemplate from './CashCardTemplate';
import CounterpartiesTable from './CounterpartiesTable';

const CounterpartiesCard = ({ projectId, currencyCode, updateCashProjectPayment }) => {
    const [value, setValue] = React.useState(0);
    const [selectedCompany, setSelectedCompany] = useState({ TaxNumber: null, ShortName: null });
    const [paymentsKey, setPaymentsKey] = useState(0); // Add a state to manage the key

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const slicerGridSize = isSmallScreen ? 12 : 6;

    const filterCompany = ({ TaxNumber, ShortName }) => {
        myDebugPrint(`filterCompany: TaxNumber, ShortName: ${TaxNumber}, ${ShortName}`)
        if (!TaxNumber || selectedCompany.TaxNumber === TaxNumber) {
            setSelectedCompany({ 'TaxNumber': null, 'ShortName': null })
            return;
        }
        setSelectedCompany({ 'TaxNumber': TaxNumber, 'ShortName': ShortName })
        myDebugPrint(`filterCompany: selected company: ${JSON.stringify(selectedCompany)}`)
        return;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                display={value !== index ? 'none' : 'block'}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };


    return (
        <CashCardTemplate
            title="Обороты по ключевым контрагентам"
            subheader={
                <span>
                    Смотрите подробный анализ в разделе <a
                        href={`cash?project=${projectId}&s=risk`}
                        style={{ textDecoration: 'none', color: '#1976d2' }}
                    >
                        Риски
                    </a>
                    <Divider />
                    счета, открытые в руб.
                </span>
            }
            cardcontent={
                <CardContent>
                    {selectedCompany && selectedCompany.TaxNumber &&
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={slicerGridSize / 2} key="filter-company">
                                <Slicer
                                    title="Контрагент"
                                    items={[
                                        (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                        + (`ИНН ${selectedCompany.TaxNumber}`)
                                        + (selectedCompany.ShortName ? ')' : '')
                                    ]}
                                    selectedItems={[
                                        (selectedCompany.ShortName ? selectedCompany.ShortName + ' (' : '')
                                        + (`ИНН ${selectedCompany.TaxNumber}`)
                                        + (selectedCompany.ShortName ? ')' : '')
                                    ]}
                                    onToggle={() => filterCompany({ TaxNumber: null })}
                                    onSelectAll={() => filterCompany({ TaxNumber: null })}
                                    onDeselectAll={() => filterCompany({ TaxNumber: null })}
                                    order={true}
                                    columnsOnXs={1}
                                    columnsOnMD={1}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '24px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Поступления" {...a11yProps(0)} />
                            <Tab label="Платежи" {...a11yProps(1)} />
                            <Tab label="Встречный оборот" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CounterpartiesTable
                        key={projectId + '~' + 'inflows'}
                        direction={['Inflows', 'Outflows', 'Sumflows'][value]}
                        projectId={projectId}
                        currencyCode={currencyCode}
                        recordsPerPage={20}
                        filterCompany={filterCompany}
                        selectedCompany={selectedCompany}
                    />
                    {selectedCompany && selectedCompany.TaxNumber &&
                        <Box>
                            <br />
                            <Divider />
                            <br />
                            <PaymentsTable
                                key={paymentsKey} // Add the key prop
                                projectId={projectId}
                                taxNumbers={[selectedCompany.TaxNumber]}
                                currencyCode={'810'}
                                recordsPerPage={10}
                                updateCashProjectPayment={updateCashProjectPayment}
                            />
                        </Box>
                    }
                </CardContent>
            }
        />
    );
};

export default CounterpartiesCard;


