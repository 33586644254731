import { Intl } from 'intl';
import CryptoJS from 'crypto-js';// src/utils/functions.js
import { Tooltip } from '@mui/material';
import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { myDebugPrint } from './debug';
import { monthNames } from './vars';
import { parse, format as fnsFormat } from 'date-fns';

/**
 * Parses a date string and returns a Date object or null if invalid.
 * @param {string} dateString - The date string to parse.
 * @return {Date|null} The parsed date or null if invalid.
 */
export const parseDateFromNullableString = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date) ? null : date;
};

export const roundTo = (number, digits) => {
    const factor = Math.pow(10, digits);
    return Math.round(number * factor) / factor;
};

export const formatDoubleToPaddedString = ({ number, roundDigits = 0, onErrorReturn = 'Invalid number' } = {}) => {
    try {
        number = number * 1.0
        number = Math.round((number + Number.EPSILON) * 10 ** roundDigits) / 10 ** roundDigits
    } catch { }
    if (typeof number !== 'number' || isNaN(number)) {
        myDebugPrint(`Invalid number provided: number:${number}`);
        return onErrorReturn;
    }

    try {
        if (typeof Intl !== 'undefined' && typeof Intl.NumberFormat === 'function') {
            const formatter = new Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: roundDigits,
                maximumFractionDigits: roundDigits,
            });
            return formatter.format(number);
        } else {
            // Fallback for environments without Intl.NumberFormat
            const options = { minimumFractionDigits: roundDigits, maximumFractionDigits: roundDigits };
            const formattedNumber = number.toLocaleString('ru-RU', options);
            return formattedNumber;
        }
    } catch (e) {
        myDebugPrint(`Error formatting number: ${e}`);
        return number.toFixed(roundDigits); // Fallback in case of error
    }
};

export const returnShareString = ({ number, base = 1 } = {}) => {
    try {
        let share = Math.round(((number / base * 1000) + Number.EPSILON)) / 10;
        if (share < 10 && share > -10) {
        } else {
            share = Math.round(share)
        }
        return `${share}%`.replace('.', ',');
    } catch { return ('н/д') }
};


// Function to handle launching URLs (EULA, Privacy Policy)
export const myLaunchURL = ({ link, newTab = true }) => {
    window.open(link, newTab ? '_blank' : '_self');
};



export function hashPassword(rawPassword, email) {

    if (!rawPassword || !email) {
        throw new Error('Пароль и email обязательны');
    }
    const saltedPassword = process.env.REACT_APP_PASSWORD_HASHER + rawPassword + email;
    const hashedPassword = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(saltedPassword)).toString();

    return hashedPassword;
}



// Format the DateTime object to the desired format
export function formatDate(dateTime) {
    if (!dateTime) return null;
    try {
        const date = new Date(dateTime);
        const res = `${date.getDate().toString().padStart(2, '0')}.`
            + `${(date.getMonth() + 1).toString().padStart(2, '0')}.`
            + `${date.getFullYear().toString().padStart(4, '0')}`;
        return res;
    } catch (e) {
        return null;
    }
}

// Calculate the time difference
export function timeDifference(pastDate) {
    if (!pastDate) return null;
    try {
        const today = new Date();
        const past = new Date(pastDate);
        const difference = today - past;

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (days < 30) {
            if (days % 10 === 1 && days % 100 !== 11) {
                return `${days} день назад`;
            } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
                return `${days} дня назад`;
            } else {
                return `${days} дней назад`;
            }
        } else if (months < 12) {
            if (months % 10 === 1 && months % 100 !== 11) {
                return `больше ${months} месяца назад`;
            } else if ([2, 3, 4].includes(months % 10) && ![12, 13, 14].includes(months % 100)) {
                return `больше ${months} месяцев назад`;
            } else {
                return `больше ${months} месяцев назад`;
            }
        } else {
            if (years % 10 === 1 && years % 100 !== 11) {
                return `больше ${years} года назад`;
            } else if ([2, 3, 4].includes(years % 10) && ![12, 13, 14].includes(years % 100)) {
                return `больше ${years} лет назад`;
            } else {
                return `больше ${years} лет назад`;
            }
        }
    } catch (e) {
        return null;
    }
}

// Get status color
export function getStatusColor(status) {
    switch (status) {
        case 'active':
            return 'green';
        case 'liquidated':
            return 'red';
        case 'bankrupt':
            return 'orange';
        default:
            return 'grey';
    }
}


// Build text line
export function buildTextLine({
    bulletShow = false,
    bullet = '● ',
    bulletColor,
    tooltipShow = false,
    tooltipText = '',
    tooltipTitle,
    tooltipUnbold = false,
    title,
    titleColor,
    onTitleTap,
    content,
    url,
    moreSpans,
}) {
    return (
        <Typography variant="body2" component="div">
            {bulletShow && <span style={{ color: bulletColor }}>{bullet}</span>}
            {tooltipShow && (tooltipTitle || title) ? (
                <Tooltip title={<span>{tooltipText}</span>} placement="top">
                    <span
                        className='pHeaderBusinessDetailsTooltip'
                        style={{
                            fontWeight: tooltipUnbold ? 'normal' : '',
                        }}
                    >
                        {tooltipTitle || title}
                    </span>
                </Tooltip>
            ) : (
                <>
                    {title && (
                        <span
                            onClick={onTitleTap}
                            className={onTitleTap
                                ? 'pHeaderBusinessDetails pseudoHrefWoColor'
                                : 'pHeaderBusinessDetails'
                            }
                            style={{
                                color: titleColor || '',
                            }}
                        >
                            {title}
                        </span>
                    )}
                    {!url && <span>{content}</span>}
                    {url && (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {title || content}
                        </a>
                    )}
                    {moreSpans && moreSpans.map((span, index) => <span key={index}>{span}</span>)}
                </>
            )
            }
        </Typography >
    );
}

buildTextLine.propTypes = {
    bulletShow: PropTypes.bool,
    bullet: PropTypes.string,
    bulletColor: PropTypes.string,
    tooltipShow: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipTitle: PropTypes.string,
    tooltipUnbold: PropTypes.bool,
    title: PropTypes.string,
    titleColor: PropTypes.string,
    onTitleTap: PropTypes.func,
    content: PropTypes.string.isRequired,
    url: PropTypes.string,
    moreSpans: PropTypes.arrayOf(PropTypes.node),
};


export const TextWithNewlines = ({ text }) => {
    // Split the string by newline characters
    const lines = text.split('\n');

    return (
        <div>
            {lines.map((line, index) => (
                <p style={{ margin: 0 }} key={index}>{line}</p>
            ))}
        </div>
    );
};

export const maxKeyInObject = (object) => {
    let maxKey;
    for (const [key, value] of Object.entries(object)) {
        if (!maxKey || key > maxKey) {
            maxKey = key;
        }
    }
    return maxKey;
}

export function shortenCashNumbers({ number, roundDigits = 0 }) {
    if (number === 0) return '0';

    const measuresAndExponents = {
        'трлн.': 12,
        'млрд.': 9,
        'млн.': 6,
        'тыс.': 3,
        ' ': 1,
    };

    const absNumber = Math.abs(number);

    const getString = (baseExponent) => {
        if (absNumber > Math.pow(10, baseExponent + 1)) {
            return formatDoubleToPaddedString({
                number: number / Math.pow(10, baseExponent),
                roundDigits: roundDigits
            });
        }
        return formatDoubleToPaddedString({
            number: number / Math.pow(10, baseExponent),
            roundDigits: roundDigits || 1
        });
    };

    let res = '';
    for (const [key, value] of Object.entries(measuresAndExponents)) {
        if (res === '') {
            if (absNumber > Math.pow(10, value)) res = `${getString(value)}${key}`;
        }
    }

    return res.trim();
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCurrencyName(code) {
    let name = '';
    if (code == '810') {
        return 'руб.';
    }
    return name;
}

export const formatMonth = (monthKey) => {
    try {
        const [year, month] = monthKey.split('-');
        return `${monthNames[parseInt(month) - 1]} ${year}`;
    } catch {
        return monthKey
    }
};

// Function to parse date strings into Date objects
export function parseDate({ dateString, format = 'dd.MM.yyyy' }) {
    return parse(dateString, format, new Date())
};

// Function to format Date objects into strings
export function formatDateToGivenFormat({ date, format = 'MMM.yyyy' }) {
    return fnsFormat(date, format)
};

export function parseAndFormatDate({ dateString, sourceFormat, targetFormat = 'dd.MM.yyyy' }) {
    if (!sourceFormat) { return 'Unknown date format' }

    return (
        formatDateToGivenFormat({
            date: parseDate({
                dateString: dateString,
                format: sourceFormat
            }),
            format: targetFormat
        })
    )
}

export const shareOfTransactions = (text) => {
    try {
        let arr = text.split("/");
        const share = returnShareString({ number: arr[0] / arr[1] });
        return ` обработано ${share} транзакций (${text})`;
    } catch (e) {
        return '';
    }
};
