import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Paper, Divider, ListItemIcon, Pagination } from '@mui/material';
import ApiManager from '../utils/api'; // Assuming getHistory is part of the ApiManager
import { myDebugPrint } from '../utils/debug';
import MyProcessingStatusIcon from './common/MyProcessingStatusIcon';
import CashCardTemplate from './cashAnalysis/cards/CashCardTemplate';

const MyHistory = () => {
    const [history, setHistory] = useState({ search: [], cashProjects: [], companies: [] });
    const [loading, setLoading] = useState(true);

    // Pagination states for cash projects and companies
    const [cashProjectPage, setCashProjectPage] = useState(1);
    const [companyPage, setCompanyPage] = useState(1);
    const itemsPerPage = 5;  // Number of items per page

    // Handle pagination change for Cash Projects
    const handleCashProjectPageChange = (event, value) => {
        setCashProjectPage(value);
    };

    // Handle pagination change for Companies
    const handleCompanyPageChange = (event, value) => {
        setCompanyPage(value);
    };

    useEffect(() => {
        const fetchHistory = async () => {
            setLoading(true);
            try {
                const response = await ApiManager.getMyHistory({ typeOfData: null, page: null });
                setHistory(response);
            } catch (error) {
                myDebugPrint("Error fetching history:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);

    if (loading) {
        return <></>;
    }

    // Pagination logic for Cash Projects
    const startCashProjectIndex = (cashProjectPage - 1) * itemsPerPage;
    const endCashProjectIndex = startCashProjectIndex + itemsPerPage;
    const paginatedCashProjects = (history.cashProjects && history.cashProjects.length > 0) ? history.cashProjects.slice(startCashProjectIndex, endCashProjectIndex) : [];

    // Pagination logic for Companies
    const startCompanyIndex = (companyPage - 1) * itemsPerPage;
    const endCompanyIndex = startCompanyIndex + itemsPerPage;
    const paginatedCompanies = (history.companies && history.companies.length > 0) ? history.companies.slice(startCompanyIndex, endCompanyIndex) : [];

    return (
        <Box sx={{ mt: 5 }}>
            {history && ((history.cashProjects && history.cashProjects.length > 0) || (history.companies && history.companies.length > 0)) &&
                <Box>
                    <Typography variant="h2" sx={{ mb: 2, fontSize: '2.5em' }}>
                        Или вернитесь к предыдущим задачам
                    </Typography>

                    {/* Display Cash Projects with Pagination if they exist */}
                    {history.cashProjects && history.cashProjects.length > 0 && (
                        <CashCardTemplate
                            title='Актуальные проекты анализа ДДС'
                            subheader='С этими проектами вы работали в последнее время'
                            cardcontent={
                                <>
                                    <List>
                                        {paginatedCashProjects.map((project) => (
                                            <ListItem key={project.id}>
                                                <ListItemIcon>
                                                    <MyProcessingStatusIcon processingStatus={project.ProccessingStatus} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                                                            href={"\\cash?project=" + project.ProjectId}>
                                                            {project.ProjectName}
                                                        </a>
                                                    }
                                                    secondary={project.object ? 'Объект анализа: ' + project.object : <span>&nbsp;</span>}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, mb: 5 }}>
                                        <Pagination
                                            count={Math.ceil(history.cashProjects.length / itemsPerPage)}
                                            page={cashProjectPage}
                                            onChange={handleCashProjectPageChange}
                                            color="primary"
                                        />
                                    </Box>
                                </>
                            }
                        />
                    )}

                    {/* Display Companies with Pagination if they exist */}
                    {history.companies && history.companies.length > 0 && (
                        <CashCardTemplate
                            title='Последние проверенные компании и ИП'
                            subheader='Компании и ИП, которые вы недавно смотрели'
                            cardcontent={
                                <>
                                    <List>
                                        {paginatedCompanies.map((company) => (
                                            <ListItem key={company.stateNumber}>
                                                <ListItemText
                                                    primary={
                                                        <a style={{ textDecoration: 'none', cursor: 'pointer', color: '#1976D2' }}
                                                            href={"\\" + company.stateNumber}>
                                                            {company.name ? company.name : company.fullName}
                                                        </a>
                                                    }
                                                    secondary={
                                                        'ИНН: ' + company.taxNumber
                                                        + ' ОГРН: ' + company.stateNumber
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, mb: 5 }}>
                                        <Pagination
                                            count={Math.ceil(history.companies.length / itemsPerPage)}
                                            page={companyPage}
                                            onChange={handleCompanyPageChange}
                                            color="primary"
                                        />
                                    </Box>
                                </>
                            }
                        />
                    )}
                </Box>
            }
        </Box>
    );
};

export default MyHistory;
