import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, useMediaQuery, List, ListItem, ListItemText, CircularProgress,
    TableSortLabel
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CircleLoader from 'react-spinners/CircleLoader';
import ApiManager from '../../../utils/api';
import { formatDoubleToPaddedString, parseAndFormatDate, parseDate } from '../../../utils/utils';
import { ThemeProvider } from '@emotion/react';
import { myDebugPrint } from '../../../utils/debug';
import { useSnackbar } from '../../../context/SnackbarContext';
import TransactionTypeEditor from '../elements/TransactionTypeEditor';
import { Edit } from '@mui/icons-material';


const PaymentsTable = ({ transactionTypes, projectId, currencyCode, recordsPerPage, updateCashProjectPayment, taxNumbers }) => {
    const [page, setPage] = useState(0);
    const [payments, setPayments] = useState([]);
    const showSnackbar = useSnackbar();
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState({ 'TransactionDate': -1 }); // sorting state
    const [editingPaymentId, setEditingPaymentId] = useState(null);
    const [editingPaymentType, setEditingPaymentType] = useState('');
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const apiPageSize = 50; // Records to fetch per request

    const fetchPayments = async (clear, newPage) => {
        if (loading) return;
        setLoading(true);
        try {
            const apiPage = clear ? 0 : Math.floor(payments.length / apiPageSize) + 1
            const response = await ApiManager.cashGetPayments({
                projectId: projectId,
                filterTypes: transactionTypes,
                taxNumbers: taxNumbers,
                currencyCode: currencyCode,
                page: apiPage,
                sorting: sorting,
            });
            myDebugPrint(`i was fetched and i am trying to use setstate`);
            if (response && response.records && response.totalCount) {
                setPayments((prev) => {
                    // Concat new records
                    let combinedPayments = [];
                    if (clear) {
                        combinedPayments = [...response.records];
                    } else {
                        combinedPayments = [...prev, ...response.records];
                    }
                    // Remove duplicates based on PaymentDocumentID
                    const uniquePayments = Array.from(new Set(combinedPayments.map(payment => payment.PaymentDocumentID)))
                        .map(id => combinedPayments.find(payment => payment.PaymentDocumentID === id));
                    return uniquePayments;
                });
                setTotalCount(response.totalCount);
                if (clear) {
                    setPage(0);
                }
                if (newPage) {
                    setPage(newPage);
                }
            } else {
                showSnackbar(`Невозможно загрузить платежи согласно указанным фильтрам.`)
            }
        } catch (error) {
            myDebugPrint(`Error fetching payments on project: ${projectId}, error: ${JSON.stringify(error)}`);
            showSnackbar(`Невозможно загрузить платежи. Если проблема сохраняется, напишите на info@brpadvice.ru`)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!loading) {
            setLoading(true)
            console.log("loaded");
            fetchPayments();
        }
    }, []);
    useEffect(() => {
        if (!loading) {
            setLoading(true)
            console.log("loaded");
            fetchPayments({ clear: true });
        }
    }, [sorting]); // Fetch data when sorting changes

    const handleChangePage = (event, newPage) => {
        const totalPagesFetched = Math.ceil(payments.length / recordsPerPage);
        if (newPage >= totalPagesFetched && newPage * recordsPerPage < totalCount) {
            fetchPayments(false, newPage);
        } else {
            setPage(newPage);
        }
    };

    const handleSort = (field) => {
        const currentOrder = sorting[field] || 0;
        const newOrder = currentOrder === 1 ? -1 : 1;
        setSorting({ [field]: newOrder });
    };

    const handleEditClick = (paymentId, currentType) => {
        setEditingPaymentId(paymentId);
        setEditingPaymentType(currentType);
    };

    const handleEditCancel = ({ oldTransactionType, newTransactionType, transactionDate, paymentAmountWithSign, currencyCode }) => {
        setEditingPaymentId(null);
        setEditingPaymentType('');

        updateCashProjectPayment({
            oldTransactionType: oldTransactionType,
            newTransactionType: newTransactionType,
            transactionDate: transactionDate,
            paymentAmountWithSign: paymentAmountWithSign,
            currencyCode: currencyCode,
        })

        myDebugPrint(`handleEditCancel done`)
    };

    const handleEditSave = (newType) => {
        let oldType;
        let date;
        let paymentAmountWithSign;
        let currencyCode;
        setPayments((prev) =>
            prev.map((payment) => {
                if (payment.PaymentDocumentID === editingPaymentId) {
                    oldType = payment.TransactionType;
                    date = parseDate({
                        dateString: payment.TransactionDate,
                        format: 'yyyy-MM-dd',
                    });
                    currencyCode = payment.MyAccountNumber.slice(5, 8)
                    paymentAmountWithSign = payment.PaymentAmount * (payment.Direction == 'out' ? -1 : 1)
                    return { ...payment, TransactionType: newType }
                }
                return payment
            })
        );
        if (transactionTypes && !transactionTypes.includes(newType)) {
            showSnackbar('Новый тип транзакции не включен в фильтры', 'warning')
        }
        myDebugPrint(`payment edit: id:${editingPaymentId}, oldType: ${oldType}, newType: ${newType}`)
        handleEditCancel({
            oldTransactionType: oldType,
            newTransactionType: newType,
            transactionDate: date,
            paymentAmountWithSign: paymentAmountWithSign,
            currencyCode: currencyCode,
        });
    };

    const getVisiblePayments = () => {
        const startIndex = page * recordsPerPage;
        const endIndex = startIndex + recordsPerPage;
        return payments.slice(startIndex, endIndex);
    };

    // if (loading) {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    //             <CircleLoader color="#8A4B9A" className='spinner' size={45} />
    //         </div>
    //     );
    // }


    const theme = createTheme({
        components: {
            MuiTableRow: {
                variants: [
                    {
                        props: { variant: 'odd' },
                        style: {
                            backgroundColor: '#f9f9f9',
                        },
                    },
                    {
                        props: { variant: 'even' },
                        style: {
                            backgroundColor: 'transparent',
                        },
                    },
                ],
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: 'small',
                        backgroundColor: null,
                    },
                    head: {
                        fontWeight: '800',
                        backgroundColor: 'lavender',
                    }
                },
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Paper style={{ position: 'relative' }}>
                {loading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        zIndex: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CircleLoader color="#8A4B9A" size={45} />
                    </div>
                )}
                {!isSmallScreen && (
                    <TableContainer>
                        <Table stripe='odd'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={!!sorting.TransactionDate}
                                            direction={sorting.TransactionDate === -1 ? 'desc' : 'asc'}
                                            onClick={() => handleSort('TransactionDate')}
                                        >
                                            Дата
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={!!sorting.PaymentAmount}
                                            direction={sorting.PaymentAmount === -1 ? 'desc' : 'asc'}
                                            onClick={() => handleSort('PaymentAmount')}
                                        >
                                            Сумма
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={!!sorting.Counterparty}
                                            direction={sorting.Counterparty === -1 ? 'desc' : 'asc'}
                                            onClick={() => handleSort('Counterparty')}
                                        >
                                            Контрагент
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={!!sorting.PaymentPurpose}
                                            direction={sorting.PaymentPurpose === -1 ? 'desc' : 'asc'}
                                            onClick={() => handleSort('PaymentPurpose')}
                                        >
                                            Назначение платежа
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={!!sorting.TransactionType}
                                            direction={sorting.TransactionType === -1 ? 'desc' : 'asc'}
                                            onClick={() => handleSort('TransactionType')}
                                        >
                                            Тип транзакции
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {payments &&
                                <TableBody>
                                    {getVisiblePayments().map((payment, index) => (
                                        <TableRow key={index} variant={index % 2 ? 'even' : 'odd'}>
                                            <TableCell>
                                                <span style={{ textWrap: 'nowrap' }}>
                                                    {parseAndFormatDate({
                                                        dateString: payment.TransactionDate,
                                                        sourceFormat: 'yyyy-MM-dd',
                                                        targetFormat: 'dd.MM.yyyy'
                                                    })}
                                                </span>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'right' }}>
                                                <span style={{
                                                    textWrap: 'nowrap',
                                                    color:
                                                        payment.Direction === 'in'
                                                            ? 'green'
                                                            : 'red'
                                                }}
                                                >
                                                    <strong>
                                                        {payment.Direction === 'in' ? '+' : '-'}
                                                    </strong>
                                                    &nbsp;
                                                    {formatDoubleToPaddedString({ number: payment.PaymentAmount, roundDigits: 2 })}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <a
                                                    href={'/search?q=' + (
                                                        (payment.CounterpartyINN
                                                            && payment.CounterpartyINN != ''
                                                            && payment.CounterpartyINN != '0'
                                                            && payment.CounterpartyINN != '0000000000'
                                                            && payment.CounterpartyINN != '000000000000'
                                                        ) ? payment.CounterpartyINN : payment.Counterparty
                                                    )}
                                                    target='_blank'
                                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                                >
                                                    {payment.Counterparty}
                                                    {payment.CounterpartyINN && <span>
                                                        <br />
                                                        {payment.CounterpartyINN}
                                                    </span>
                                                    }
                                                </a>
                                            </TableCell>
                                            <TableCell>{payment.PaymentPurpose}</TableCell>
                                            <TableCell>
                                                {editingPaymentId === payment.PaymentDocumentID ? (
                                                    <TransactionTypeEditor
                                                        projectId={projectId}
                                                        paymentId={payment.PaymentDocumentID}
                                                        currentType={editingPaymentType}
                                                        onSave={handleEditSave}
                                                        onCancel={handleEditCancel}
                                                    />
                                                ) : (
                                                    <span
                                                        onClick={() => handleEditClick(payment.PaymentDocumentID, payment.TransactionType)}
                                                        style={{ cursor: 'pointer', color: '#1976d2' }}
                                                    >
                                                        <Edit
                                                            fontSize='inherit'
                                                        />
                                                        {' '}
                                                        {payment.TransactionType}
                                                    </span>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            }
                        </Table>
                    </TableContainer>
                )}
                {isSmallScreen && payments &&
                    <List>
                        {getVisiblePayments().map((payment, index) => (
                            <ListItem key={index} alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <>
                                            <strong>{payment.Counterparty}</strong>, {payment.TransactionDate}
                                            <br />
                                            {payment.TransactionType}
                                            <br />
                                            <span style={{
                                                textWrap: 'nowrap',
                                                color:
                                                    payment.Direction === 'in'
                                                        ? 'green'
                                                        : 'red'
                                            }}
                                            >
                                                <strong>
                                                    {payment.Direction === 'in' ? '+' : '-'}
                                                </strong>
                                                &nbsp;
                                                {formatDoubleToPaddedString({ number: payment.PaymentAmount, roundDigits: 2 })}
                                            </span>
                                            <br />
                                            Назначение платежа: {payment.PaymentPurpose}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                }
                <TablePagination
                    component="div"
                    count={totalCount}
                    rowsPerPage={recordsPerPage}
                    page={page ?? 1}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[recordsPerPage]}
                />
            </Paper>
        </ThemeProvider>
    );
};

export default PaymentsTable;
