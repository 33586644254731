import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Card, CardHeader, CardContent, Grid, List, ListItem, Typography, } from '@mui/material';
import { formatDoubleToPaddedString, getCurrencyName, parseDate, returnShareString, shareOfTransactions } from '../../utils/utils';
import { myDebugPrint } from '../../utils/debug';
import AccountOverallData from './cards/AccountOverallData';
import OverallByMonths from './cards/OverallsByMonths';
import OverallByTransactionTypes from './cards/OverallsByTransactionTypes';
import CashCardTemplate from './cards/CashCardTemplate';
import CounterpartiesCard from './cards/CounterpartiesCard';
import RecalculateProjectCard from './cards/RecalculateProjectCard';
import BusinessCard from '../BusinessCard';
import FileProcessingResults from './cards/FileProcessingResults';
import StatementsWithZeroTurnover from './cards/StatementsWithZeroTurnover';
import MissingAccounts from './cards/MissingAccounts';



const ReportCashData = ({ cashProject, cashUploader, fetchingReanalyzer, reanalyzePayments, refetch, updateCashProjectPayment }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            {/* {cashUploader} */}
            {
                cashProject.processingStatus != 'done' &&
                <Box>
                    <Typography variant='body2'>
                        Идет обработка загруженных выписок...
                        {cashProject.processingStatus
                            && cashProject.processingStatus.includes('/') ?
                            shareOfTransactions(cashProject.processingStatus) : ''
                        }
                        <br />
                        <br />
                        Обычно обработка выписок 1C и анализ всех транзакций занимает несколько минут, а для Excel - от нескольких минут до 1 дня.
                        <br />
                        <br />
                        Статус на этой странице не будет обновляться автоматически.
                        Перезагрузите страницу через 5-7 минут.
                    </Typography>
                </Box>

            }
            {cashProject.processingStatus == 'done' &&
                <Box>
                    {cashProject.myCompanies && cashProject.myCompanies.length > 0 &&
                        <Box sx={{ marginBottom: '45px' }}>
                            {
                                cashProject.myCompanies.map((entity, index) => (
                                    < BusinessCard
                                        key={entity.taxNumber + '_' + index}
                                        entity={entity}
                                        showCEO={true}
                                        newtab={true}
                                    // showShareholders={true}
                                    />
                                ))
                            }
                        </Box>
                    }
                    {cashProject.statementsSummary &&
                        <Box>
                            <FileProcessingResults
                                statementsSummary={cashProject.statementsSummary}
                                errorsReport={cashProject.errorsReport}
                            />
                        </Box>
                    }
                    {cashProject.statementsSummary && cashProject.collectedTaxNumbersAndAccounts &&
                        <Box>
                            <MissingAccounts
                                statementsSummary={cashProject.statementsSummary}
                                collectedTaxNumbersAndAccounts={cashProject.collectedTaxNumbersAndAccounts}
                            />
                        </Box>
                    }
                    <StatementsWithZeroTurnover
                        statementsSummary={cashProject.statementsSummary}
                    />
                    {cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0 &&
                        <Box>
                            <CashCardTemplate
                                title='Общие сведения об оборотах по счетам'
                                cardcontent={
                                    <CardContent>
                                        <List>
                                            {Object.entries(cashProject.overalls_by_accounts).map((account, index) => {
                                                return (<AccountOverallData
                                                    key={account[1]["MyAccountNumber"] + '-' + index}
                                                    account={account[1]}
                                                    index={index}
                                                    earliestDate={cashProject.earliestDate}
                                                    accountStatements={
                                                        (
                                                            cashProject.accountStatements
                                                            && cashProject.accountStatements[account[1]["MyAccountNumber"]]
                                                        ) ? cashProject.accountStatements[account[1]["MyAccountNumber"]]
                                                            : null
                                                    }
                                                />);
                                            }
                                            )}
                                        </List>
                                    </CardContent>
                                }
                            />
                        </Box>
                    }
                    {cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0 &&
                        <OverallByMonths
                            transactionData={cashProject.overalls_by_months}
                        />
                    }
                    {((cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0) ||
                        (cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0)) &&
                        <OverallByTransactionTypes
                            transactionData={cashProject.transactiontype}
                            projectId={cashProject.projectId}
                            updateCashProjectPayment={updateCashProjectPayment}
                        />
                    }
                    {/* } */}
                    {((cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0) ||
                        (cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0)) && cashProject &&
                        <CounterpartiesCard
                            projectId={cashProject.projectId}
                            currencyCode='810'
                            updateCashProjectPayment={updateCashProjectPayment}
                        />
                    }
                    {((cashProject.overalls_by_accounts && cashProject.overalls_by_accounts.length > 0) ||
                        (cashProject.overalls_by_months && cashProject.overalls_by_months.length > 0)) &&
                        <Box >
                            <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                                <Grid item xs={12} md={4}>
                                    <Box sx={{ width: '100%', }} >
                                        <RecalculateProjectCard
                                            fetchingReanalyzer={fetchingReanalyzer}
                                            reanalyzePayments={reanalyzePayments}
                                            type='reanalyze'
                                        />
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                <Box sx={{ width: '100%', }} >

                                    <RecalculateProjectCard
                                        fetchingReanalyzer={fetchingReanalyzer}
                                        reanalyzePayments={reanalyzePayments}
                                        type='retotal'
                                    />
                                </Box>
                            </Grid> */}
                            </Grid >
                        </Box>}
                </Box>
            }
        </Box>
    );
}

export default ReportCashData;



