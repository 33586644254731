import React, { useState } from 'react';
import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Tooltip } from '@mui/material';
import CashCardTemplate from './CashCardTemplate';
import { formatDoubleToPaddedString, parseAndFormatDate, parseDateFromNullableString } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';
import { Check, HelpOutline, Warning } from '@mui/icons-material';

const validateAccNmbr = (accNmbr) => {
    const cleanedAccNmbr = accNmbr.replace(/\D/g, '');
    return cleanedAccNmbr.length === 20 ? cleanedAccNmbr : 'нет данных';
};

const validateDate = (date) => {
    let res = 'нет данных'
    try {
        res = parseAndFormatDate({ dateString: date, sourceFormat: 'dd.MM.yyyy' })
    } catch { }
    return res;
};

const validateNumber = (value, roundDigits = 2) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const StatementsWithZeroTurnover = ({ statementsSummary }) => {
    const renderTableRows = () => {
        const rows = [];
        let i = -1;

        statementsSummary.forEach(statement => {
            const fileName = Object.keys(statement)[0];
            const fileData = statement[fileName];

            fileData.forEach((data, index) => {
                i = i + 1
                if (data.Outflow === 0 && data.Inflow === 0) {

                    rows.push(
                        <TableRow variant={(i % 2 !== 0) ? "oddCashAnalysis" : "evenCashAnalysis"} key={`${fileName}-${index}`}>
                            <TableCell variant="cashAnalysis">{validateAccNmbr(data.AccNmbr)}</TableCell>
                            <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                                {`${validateDate(data.DateStart)} - ${validateDate(data.DateEnd)}`}
                            </TableCell>
                            <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.WasOnStart)}</TableCell>
                            <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.Outflow)}</TableCell>
                            <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.Inflow)}</TableCell>
                            <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.WasOnEnd)}</TableCell>
                        </TableRow>
                    );

                }
            });
        });

        return rows;
    };

    const data = renderTableRows();

    const tableContent = (
        <CardContent>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Счет</TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2} sx={{ textAlign: 'center' }}>Период</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={4} sx={{ textAlign: 'center' }}>Данные по итогам</TableCell>
                        </TableRow>
                        <TableRow>
                            {/* These cells correspond to the sub-headers under "Данные по итогам" */}
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на начало</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Расходы</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Приходы</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на конец</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    );

    if (data && data.length > 0) {
        return (
            <CashCardTemplate
                title='Выписки с нулевыми оборотами'
                subheader=""
                cardcontent={tableContent}
            />
        );
    }
    return <Box></Box>
};

export default StatementsWithZeroTurnover;
