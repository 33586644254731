import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Card, CardHeader, CardContent, Grid, List, ListItem, Typography, } from '@mui/material';
import { formatDoubleToPaddedString, getCurrencyName, parseDate, returnShareString, shareOfTransactions } from '../../utils/utils';
import { myDebugPrint } from '../../utils/debug';
import AccountOverallData from './cards/AccountOverallData';
import OverallByMonths from './cards/OverallsByMonths';
import OverallByTransactionTypes from './cards/OverallsByTransactionTypes';
import CashCardTemplate from './cards/CashCardTemplate';
import CounterpartiesCard from './cards/CounterpartiesCard';
import RecalculateProjectCard from './cards/RecalculateProjectCard';
import BusinessCard from '../BusinessCard';
import FileProcessingResults from './cards/FileProcessingResults';
import StatementsWithZeroTurnover from './cards/StatementsWithZeroTurnover';
import MissingAccounts from './cards/MissingAccounts';
import RisksMainData from './cards/RisksMainData';



const ReportCashRisk = ({ cashProject, updateCashProjectPayment, }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            {/* {cashUploader} */}
            {
                cashProject.processingStatus != 'done' &&
                <Box>
                    <Typography variant='body2'>
                        Идет обработка загруженных выписок...
                        {cashProject.processingStatus
                            && cashProject.processingStatus.includes('/') ?
                            shareOfTransactions(cashProject.processingStatus) : ''
                        }
                        <br />
                        <br />
                        Обычно обработка выписок 1C и анализ всех транзакций занимает несколько минут, а для Excel - от нескольких минут до 1 дня.
                        <br />
                        <br />
                        Статус на этой странице не будет обновляться автоматически.
                        Перезагрузите страницу через 5-7 минут.
                    </Typography>
                </Box>

            }
            {cashProject.processingStatus == 'done' && !(cashProject.risks) &&
                <Box>
                    <Typography variant='body2'>
                        Для новых проектов анализ рисков выполняется автоматически, а для этого проекта он был запущен только что.
                        <br />
                        <br />
                        Финрадару нужно около 10 минут, чтобы проверить контрагентов на надежность. Пока вы можете посмотреть общий отчет,
                        или просто обновите эту страницу минут через 10.
                    </Typography>
                </Box>
            }
            {cashProject.processingStatus == 'done' && (cashProject.risks) &&
                <Box>
                    <RisksMainData
                        cashProject={cashProject}
                        updateCashProjectPayment={updateCashProjectPayment}
                    />
                </Box>
            }
        </Box>
    );
}

export default ReportCashRisk;



