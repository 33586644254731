import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, ListItem, Card, CardContent, Grid, } from '@mui/material';
import { formatDoubleToPaddedString, getCurrencyName, parseDate, shortenCashNumbers } from '../../../utils/utils';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import StatementDatesChart from './StatementDatesChart';
import { myDebugPrint } from '../../../utils/debug';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const AccountOverallData = ({ account, accountStatements, index, earliestDate }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const currency = ' ' + getCurrencyName(account["CurrencyCode"]);

    const data = {
        labels: ['Поступления', 'Платежи'],
        datasets: [
            {
                label: 'Value',
                data: [account.Inflows ? account.Inflows : 0, account.Outflows ? account.Outflows : 0],
                backgroundColor: [inflowsColor, outflowsColor],
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: `Обороты,${currency}`,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return shortenCashNumbers({ number: value });
                    }
                }
            },
        },
    };

    return (
        <ListItem key={'li' + account["MyAccountNumber"] + '-' + index} style={{ padding: 0 }}>
            <Card style={{ width: '100%', backgroundColor: '#fcf9f9', marginBottom: '30px' }}>
                <CardContent>
                    <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'}>
                        <Grid item xs={12} sm={6}>
                            <Typography>
                                <strong>
                                    {account["MyAccountNumber"]}
                                </strong>
                                <br />
                                Поступления: {formatDoubleToPaddedString(
                                    { number: account["Inflows"], roundDigits: 0 }) + currency}
                                <br />
                                Платежи: {formatDoubleToPaddedString(
                                    { number: account["Outflows"], roundDigits: 0 }) + currency}
                                {((!account["Errorflows"]) || account["Errorflows"] != 0) ? '' :
                                    <span>
                                        <br />
                                        Нераспознанные потоки: {formatDoubleToPaddedString(
                                            { number: account["Errorflows"], roundDigits: 0 }) + currency}
                                    </span>
                                }
                                <br />
                                Изменение денежных средств: {formatDoubleToPaddedString(
                                    { number: account["Netflows"], roundDigits: 0 }) + currency}
                                {accountStatements
                                    && accountStatements.totals
                                    && accountStatements.totals.DateStart &&
                                    <span>
                                        <br />
                                        Остаток на {accountStatements.totals.DateStart}: {formatDoubleToPaddedString(
                                            { number: accountStatements.totals.WasOnStart, roundDigits: 0 }) + currency}
                                    </span>

                                }
                                {accountStatements
                                    && accountStatements.totals
                                    && accountStatements.totals.DateEnd &&
                                    <span>
                                        <br />
                                        Остаток на {accountStatements.totals.DateEnd}: {formatDoubleToPaddedString(
                                            { number: accountStatements.totals.WasOnEnd, roundDigits: 0 }) + currency}
                                    </span>

                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ maxHeight: '150px' }}>
                                <Bar data={data} options={options} />
                            </Box>
                        </Grid>
                    </Grid>
                    {accountStatements && accountStatements.data && accountStatements.data.length > 0 &&
                        <StatementDatesChart data={accountStatements.data} earliestDate={earliestDate} />
                    }
                </CardContent>

            </Card>
        </ListItem>
    );
}

export default AccountOverallData;