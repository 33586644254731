// src/components/CommonCorpSection.js
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Entity from '../../classes/Entity';
import {
    formatDate, timeDifference, getStatusColor,
    buildTextLine, formatDoubleToPaddedString, TextWithNewlines
} from '../../utils/utils';
import BusinessDetailsSkeleton from './BusinessDetailsSkeleton';

const CorpCommonSection = ({ analytics, entity, handleSearch }) => {

    const [showAllActivities, setShowAllActivities] = useState(false);

    const toggleAllActivities = () => {
        setShowAllActivities(!showAllActivities);
    };

    const dateReg = formatDate(entity.registrationDate);
    // const dateOgrn = formatDate(entity.dateOgrn);
    const dateLiquid = formatDate(entity.liquidationDate);
    const sinceDateReg = timeDifference(entity.registrationDate);
    const sinceDateLiquid = timeDifference(entity.liquidationDate);
    // const dateCEO = formatDate(entity.ceoSince);
    // const sinceDateCEO = timeDifference(entity.ceoSince)?.replace(' назад', '');
    // const screenWidth = window.innerWidth;
    // const shrinkTables = screenWidth < 600;

    const buildCeos = () => {
        return entity.ceos.map((element) => {
            const dateCEO = formatDate(element.ceoSince);
            const sinceDateCEO = timeDifference(element.ceoSince)?.replace(' назад', '');
            const datesWhenCeo = dateCEO ? `\nс ${dateCEO} (${sinceDateCEO} назад)` : '';
            const inn = element.ceoTaxNumber ? ` (ИНН ${element.ceoTaxNumber}) ` : '';
            const title = element.positionName ? ` - ${element.positionName.toLowerCase()}` : '';
            const inaccuracyText = element.inaccuracy
                ? `\n${element.inaccuracyDesc || 'Сведения недостоверны'}`
                : '';
            let disqText = '';
            if (element.disqualification) {
                disqText = '\nДисквалифицированное лицо';
                if (element.disqStartDate || element.disqEndDate) {
                    const disqDates = [];
                    if (element.disqStartDate) disqDates.push(`с ${formatDate(element.disqStartDate)}`);
                    if (element.disqEndDate) disqDates.push(`по ${formatDate(element.disqEndDate)}`);
                    disqText = `${disqText} (${disqDates.join(' ')})`;
                }
            }
            return buildTextLine({
                bulletShow: true,
                bulletColor: element.inaccuracy || element.disqualification
                    ? 'red'
                    : (element.massDirector ? 'orange' : 'black'),
                title: element.name,
                titleColor: 'blue',
                onTitleTap: () => handleSearch(element.ceoTaxNumber || element.name),
                content: `${inn}${title}${datesWhenCeo}${inaccuracyText}${disqText}`,
                key: `ceo ${inn}, ${dateCEO}`
            });
        });
    };

    const buildShareholders = () => {
        return entity.shareholders.map(element => {
            const dateHolder = formatDate(element.since);
            const sinceDateHolder = timeDifference(element.since)?.replace(' назад', '');
            const datesWhenHolder = dateHolder ? `\nс ${dateHolder} (${sinceDateHolder} назад)` : '';
            const inn = element.shareholderINN ? ` (ИНН ${element.shareholderINN}) ` : '';
            const inaccuracyText = element.inaccuracy
                ? `\n${element.inaccuracyDescription || 'Сведения недостоверны'}`
                : '';
            const sharePercent = (element.sharePercentage ?? -1) > 0
                ? `${element.sharePercentage}%`
                : null;
            const shareNominalValue = (element.shareNominalValue ?? -1) > 0
                ? `${formatDoubleToPaddedString({ number: element.shareNominalValue })} руб.`
                : null;
            let shareString = '';
            if (sharePercent || shareNominalValue) {
                if (sharePercent && shareNominalValue) {
                    shareString = `\n${sharePercent} - ${shareNominalValue}`;
                } else {
                    shareString = `\n${sharePercent || shareNominalValue}`;
                }
            }
            return buildTextLine({
                key: `sholders ${inn}, ${element.name}, ${dateHolder}`,
                bulletShow: true,
                bulletColor: element.inaccuracy
                    ? 'red'
                    : (element.massShareholderIndicator ? 'orange' : 'black'),
                title: element.name,
                onTitleTap: () => handleSearch(element.shareholderINN || element.name),
                content: `${inn}${datesWhenHolder}${shareString}${inaccuracyText}`
            });
        });
    };

    const buildCEOWidget = () => {
        return (
            <>
                <div style={{ height: 20 }} />
                {buildTextLine({
                    title: 'Руководители',
                    content: ' (лица, имеющие право действовать без доверенности):'
                })}
                {!entity.ceos || entity.ceos.length === 0
                    ? buildTextLine({ content: 'сведения отсутствуют' })
                    : buildCeos()}
            </>
        );
    };

    const buildShareholderWidget = () => {
        return (
            <>
                <div style={{ height: 20 }} />
                {entity.actualRegisteredCapitalName &&
                    buildTextLine({
                        title: `${entity.actualRegisteredCapitalName}: `,
                        content: (entity.actualRegisteredCapital ?? -1) >= 0
                            ? `${formatDoubleToPaddedString({ number: entity.actualRegisteredCapital })} руб.`
                            : 'сведения отсутствуют'
                    })}
                {buildTextLine({
                    title: 'Учредители',
                    content: ' (участники, акционеры):'
                })}
                {!entity.shareholders || entity.shareholders.length === 0
                    ? buildTextLine({ content: 'сведения отсутствуют' })
                    : buildShareholders()}
            </>
        );
    };

    return (
        <BusinessDetailsSkeleton
            entityName={entity.name}
            widgetsBeforeTitle={null}
            h1="Общие сведения"
            widgetsAfterTitleBeforeFilters={[
                buildTextLine({
                    bulletShow: true,
                    bulletColor: getStatusColor(entity.status),
                    content: `${entity.statusName || ''}${(entity.liquidationReason || '') ? ` - ${entity.liquidationReason}` : ''}${entity.liquidationDateDue ? ` (срок ликвидации - ${entity.liquidationDateDue})` : ''}`
                })
            ]}
            filteringWidgets={null}
            widgetsAfterFilters={null}
            mainContent={[
                buildTextLine({ title: 'Полное наименование: ', content: entity.fullName || '' }),
                entity.fullName !== entity.name && buildTextLine({ title: 'Краткое наименование: ', content: entity.name || '' }),
                entity.latinName && buildTextLine({ title: 'Наименование на иностранном языке: ', content: entity.latinName }),
                buildTextLine({ title: 'Образовано: ', content: `${dateReg} (${sinceDateReg})` }),
                dateLiquid && buildTextLine({ title: 'Ликвидировано: ', content: `${dateLiquid} (${sinceDateLiquid})` }),
                buildTextLine({ title: 'ИНН:  ', content: entity.taxNumber }),
                entity.taxReason && buildTextLine({ title: 'КПП:  ', content: entity.taxReason }),
                entity.stateNumber && buildTextLine({
                    title: 'ОГРН: ',
                    content: `${entity.stateNumber}
                    ${(entity.dateOgrn && entity.registrationDate && entity.dateOgrn !== entity.registrationDate)
                            ? ` от ${formatDate(entity.dateOgrn)}` : ''}`
                }),
                entity.type === 'corporation' && buildCEOWidget(),
                entity.type === 'corporation' && buildShareholderWidget(),
                entity.type === 'corporation' && <div style={{ height: 20 }} />,
                entity.type === 'corporation' &&
                <Typography variant="body2" component="div">
                    <span>
                        Предыдущие руководители, участники, а также связанные с ними организации и ИП,
                        доступны в Пакете анализа
                    </span>
                </Typography>,
                <div style={{ height: 20 }} />,
                entity.address && buildTextLine({
                    bulletShow: entity.actualAddressInvalidity === 1,
                    bulletColor: 'red',
                    title: 'Адрес: ',
                    content: `${entity.address} `,
                    moreSpans: [
                        <a href={`https://yandex.ru/maps/?text=${entity.address}`} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">(на карте)</a>,
                        entity.actualAddressInvalidity === 1 && <span>{entity.actualAddressInvalidityReason || 'Сведения об адресе недостоверны'}</span>
                    ]
                }),
                ((entity.contacts_web && entity.contacts_web.length > 0) || (entity.contacts_phone && entity.contacts_phone.length > 0) || (entity.contacts_email && entity.contacts_email.length > 0)) && <>
                    <div style={{ height: 20 }} />
                    {buildTextLine({
                        title: 'Контакты: ',
                        content: '',
                        tooltipShow: true,
                        tooltipText: "Добавлены пользователями или найдены в открытых источниках."
                    })}
                    {entity.contacts_phone && entity.contacts_phone.length > 0 && buildTextLine({ content: `Тел: ${entity.contacts_phone.join(', ')}` })}
                    {entity.contacts_email && entity.contacts_email.length > 0 && buildTextLine({ content: `Email: ${entity.contacts_email.join(', ')}` })}
                    {entity.contacts_web && entity.contacts_web.length > 0 && buildTextLine({ url: entity.contacts_web[0], content: entity.contacts_web[0] })}
                </>,
                <div style={{ height: 20 }} />,
                <Typography variant="body2" component="div">
                    <div>
                        {showAllActivities && entity.buildAllActivities().length > 200 &&
                            <span onClick={toggleAllActivities} className='pseudoHrefWithColor'>
                                Свернуть ОКВЭД<br />
                            </span>}
                        {!showAllActivities
                            ? <span>
                                <span className='pHeaderBusinessDetails'>
                                    Основной вид деятельности:
                                </span> {entity.activityName || 'не указан'}
                            </span>
                            : <span> <TextWithNewlines text={entity.buildAllActivities()} /></span>
                        }
                        <span onClick={toggleAllActivities} className='pseudoHrefWithColor'>
                            <br /> {showAllActivities ? 'Свернуть ОКВЭД' : 'Все ОКВЭД'}
                        </span>
                    </div>
                </Typography>,
                <div style={{ height: 20 }} />,
                entity.okpo && buildTextLine({ title: 'ОКПО:  ', content: entity.okpo }),
                entity.pensionNumber && buildTextLine({ title: 'ПФР:   ', content: entity.pensionNumber }),
                entity.socialNumber && buildTextLine({ title: 'ФСС:   ', content: entity.socialNumber }),
                entity.okato && buildTextLine({ title: 'ОКАТО: ', content: entity.okato }),
                entity.oktmo && buildTextLine({ title: 'ОКТМО: ', content: entity.oktmo }),
                entity.okogu && buildTextLine({ title: 'ОКОГУ: ', content: entity.okogu }),
                entity.okopf && buildTextLine({ title: 'ОКОПФ: ', content: entity.okopf }),
                entity.okfs && buildTextLine({ title: 'ОКФС:  ', content: entity.okfs }),
                <div style={{ height: 20 }} />,
                entity.nonExistingReports && entity.nonExistingReports.length > 0 && buildTextLine({
                    bulletShow: true,
                    bulletColor: 'red',
                    title: 'Финансовая отчетность',
                    content: ` за ${entity.nonExistingReports.join(', ')} ${entity.nonExistingReports.length > 1 ? 'годы' : 'год'} может быть не сдана в ФНС / Росстат.`
                }),
                entity.finRepRows && buildTextLine({ title: 'Прибыль: ', content: entity.buildRecentNetProfit() }),
                entity.finRepRows && buildTextLine({ title: 'Выручка: ', content: entity.buildRecentRevenue() }),
                entity.finRepRows && buildTextLine({ title: 'Активы:  ', content: entity.buildRecentAssets() })
            ]}
        />
    );
};

export default CorpCommonSection;
