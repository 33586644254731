import { myDebugPrint } from '../../utils/debug';
import { parseDate, parseDateFromNullableString } from '../../utils/utils';
import Entity from '../Entity';

class CashProjectClass {
    constructor({
        projectId,
        projectName,
        createdOn,
        updatedOn,
        processingStatus,
        statementsSummary,
        accountStatements,
        overalls_by_accounts,
        overalls_by_months,
        transactiontype,
        transactiontype_counterpartytype_counterparty,
        counterparty,
        errorsReport,
        myCompanies = [],
        earliestDate,
        openingBalance = [],
        closingBalance = [],
        collectedTaxNumbersAndAccounts,
        risks,
    }) {
        this.projectId = projectId;
        this.projectName = projectName;
        this.createdOn = createdOn;
        this.updatedOn = updatedOn;
        this.processingStatus = processingStatus;
        this.statementsSummary = statementsSummary;
        this.accountStatements = accountStatements;
        this.overalls_by_accounts = overalls_by_accounts;
        this.overalls_by_months = overalls_by_months;
        this.transactiontype = transactiontype;
        this.transactiontype_counterpartytype_counterparty = transactiontype_counterpartytype_counterparty;
        this.counterparty = counterparty;
        this.errorsReport = errorsReport;
        this.myCompanies = myCompanies;
        this.earliestDate = earliestDate;
        this.openingBalance = openingBalance;
        this.closingBalance = closingBalance;
        this.collectedTaxNumbersAndAccounts = collectedTaxNumbersAndAccounts;
        this.risks = risks;
    }

    static fromJson(jsonData) {
        const normalizedData = {};

        for (const key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
                normalizedData[key.toLowerCase()] = jsonData[key];
            }
        }

        const accountStatements = {};
        try {
            const stSummary = normalizedData['statementssummary'];
            stSummary.forEach(statement => {
                // Loop through each key in the statement
                Object.keys(statement).forEach(key => {
                    // Get the array of accounts for each key
                    const accounts = statement[key];
                    // Loop through each record to extract the Inflow value
                    accounts.forEach(account => {
                        if (!accountStatements[account.AccNmbr]) {
                            accountStatements[account.AccNmbr] = { 'totals': {}, 'data': [] };
                        }
                        const data = {};
                        Object.keys(account).forEach(accKey => {
                            data[accKey] = account[accKey];
                            const wasStartDate = accountStatements[account.AccNmbr]['totals']['DateStart'];
                            const wasEndDate = accountStatements[account.AccNmbr]['totals']['DateEnd'];
                            if (accKey === 'DateStart'
                                && (
                                    !wasStartDate
                                    || parseDate(account[accKey]) < parseDate(wasStartDate)
                                )
                            ) {
                                accountStatements[account.AccNmbr]['totals']['DateStart'] = data[accKey]
                                accountStatements[account.AccNmbr]['totals']['WasOnStart'] = account['WasOnStart']
                            }
                            if (accKey === 'DateEnd'
                                && (
                                    !wasEndDate
                                    || parseDate(account[accKey]) > parseDate(wasEndDate)
                                )
                            ) {
                                accountStatements[account.AccNmbr]['totals']['DateEnd'] = data[accKey]
                                accountStatements[account.AccNmbr]['totals']['WasOnEnd'] = account['WasOnEnd']
                            }
                        })
                        accountStatements[account.AccNmbr]['data'].push(data)
                    });
                });
            });
        } catch (e) {
            myDebugPrint(`error on proccessing summary 
                statements in CashProjectClass.fromJSON: ${JSON.stringify(e)}`)
        }

        let project = new CashProjectClass({
            projectId: normalizedData['projectid'],
            projectName: normalizedData['projectname'],
            createdOn: parseDateFromNullableString(normalizedData['createdOn']),
            updatedOn: parseDateFromNullableString(normalizedData['updatedon']),
            processingStatus: normalizedData['proccessingstatus'],
            statementsSummary: normalizedData['statementssummary'],
            accountStatements: accountStatements,
            overalls_by_accounts: normalizedData['overalls_by_accounts'],
            overalls_by_months: normalizedData['overalls_by_months'],
            transactiontype: normalizedData['transactiontype'],
            // transactiontype_counterpartytype_counterparty: normalizedData['transactiontype_counterpartytype_counterparty'],
            // counterparty: normalizedData['counterparty'],
            errorsReport: normalizedData['errorsreport'],
            myCompanies: [],
            collectedTaxNumbersAndAccounts: normalizedData['collectedtaxnumbersandaccounts'],
            risks: normalizedData['risks']
        });
        try {
            let entities = [];
            if (normalizedData['mycompanies'] && normalizedData['mycompanies'].length > 0) {
                normalizedData['mycompanies'].forEach((jsonCompany) => {
                    let entity = Entity.fromJson(jsonCompany);
                    entities.push(entity)
                })
            }
            if (entities.length > 0) {
                project.myCompanies = entities
            }
        } catch (e) {
            myDebugPrint(`error on parsing companies from CashProject list: ${e}`);
        }

        try {
            if (project.overalls_by_accounts) {
                let newEarliestDate = new Date().getTime();
                let openingBalance = {};
                let closingBalance = {};
                Object.entries(project.overalls_by_accounts).map((account, index) => {
                    const thisCurrency = account[1]["CurrencyCode"];
                    const accountNumber = account[1]["MyAccountNumber"];
                    try {
                        const accountStatements = project.accountStatements[account[1]["MyAccountNumber"]]
                        if (!accountStatements || !accountStatements.data || accountStatements.data.length < 1) {
                        } else {
                            accountStatements.data.map((item, index) => {
                                const dateStart = parseDate({ dateString: item.DateStart, format: 'dd.MM.yyyy' }).getTime();
                                newEarliestDate = Math.min(newEarliestDate, dateStart);
                                if (!openingBalance[thisCurrency]) {
                                    openingBalance[thisCurrency] = []
                                }
                                openingBalance[thisCurrency].push({
                                    'AccountNumber': accountNumber,
                                    'DateStart': item.DateStart,
                                    'WasOnStart': item.WasOnStart
                                })
                                if (!closingBalance[thisCurrency]) {
                                    closingBalance[thisCurrency] = []
                                }
                                closingBalance[thisCurrency].push({
                                    'AccountNumber': accountNumber,
                                    'DateEnd': item.DateEnd,
                                    'WasOnEnd': item.WasOnEnd
                                })
                            })
                        }
                    } catch (e) { }
                });
                newEarliestDate = new Date(newEarliestDate);
                newEarliestDate = new Date(
                    newEarliestDate.getFullYear(),
                    newEarliestDate.getMonth(),
                    1
                )
                project.earliestDate = newEarliestDate;
                project.openingBalance = openingBalance;
                project.closingBalance = closingBalance;
            }
        } catch (e) { }

        return project;
    }
}

export default CashProjectClass;
