import BusinessStatus from './BusinessStatus';
import BusinessType from './BusinessType';
import CEO from './CEO';
import Kad from './Kad';
import KadPartyType from './KadPartyType';
import License from './License';
import LicenseAndTrademarkValidity from './LicenseAndTrademarkValidity';
import Shareholder from './Shareholder';
import SMEMeasure from './SMEMeasure';
import Subdivision from './Subdivision';
import TaxesPaid from './TaxesPaid';
import Trademark from './Trademark';
import FinRepRowVals from './FinRepRowVals';
import { parseDateFromNullableString } from '../utils/utils';
import { myDebugPrint } from '../utils/debug';
import { indicatorsSimplifiedMap, indicatorsMap, totalsCodesForSimpleRegularMapping, simpleCasualMapping } from './FinancialIndicators';


class Entity {
    constructor({
        type,
        name,
        taxNumber,
        activityName,
        activityCode,
        address,
        taxReason,
        stateNumber,
        status,
        registrationDate,
        liquidationDate,
        liquidationDateDue,
        ceos,
        ceo,
        ceoTitle,
        shareholders,
        statusCode,
        statusName,
        internalCompanyId,
        okpo,
        pensionNumber,
        socialNumber,
        okato,
        okopf,
        okogu,
        okfs,
        dateOgrn,
        shortName,
        fullName,
        latinName,
        restrictedByLaw,
        liquidationReason,
        regionCode,
        regionName,
        actualAddress,
        actualAddressTown,
        actualAddressInvalidity,
        actualAddressInvalidityReason,
        okvedCode,
        okvedCodeVersion,
        okvedName,
        okvedsOther,
        oktmo,
        taxOfficeCode,
        taxOfficeName,
        actualRegisteredCapital,
        undilutedRegisteredCapital,
        undilutedRegisteredCapitalPercent,
        actualRegisteredCapitalName,
        holderOfSharesDataRestricted,
        holderOfSharesDataTaxNumber,
        holderOfSharesDataOGRN,
        holderOfSharesDataName,
        updatedOn,
        finRepRows,
        finRepSimplifiedRows,
        finRepRowsRetro,
        finRepSimplifiedRetro,
        minParsedYear,
        maxParsedYear,
        nonExistingReports,
        existingReports,
        simplifiedReports,
        finRepError,
        kad_update_date,
        kad_records_num,
        kad_defendant,
        kad_plaintiff,
        kad_allRecords,
        licenses_all,
        licenses_valid,
        licenses_passed,
        licenses_unknown,
        trademarks_all,
        trademarks_valid,
        trademarks_passed,
        trademarks_unknown,
        contacts_phone,
        contacts_email,
        contacts_web,
        smeString,
        smeSince,
        smeTill,
        smeMeasures_all,
        smeMeasures_violation,
        smeMeasures_wo_violation,
        taxSpecialRegimes,
        taxesPaid,
        subdivisions,
        searchKeyCEO = false,
        searchKeyShareholder = false,
        whereFound = null,
    }) {
        this.type = type;
        this.name = name;
        this.taxNumber = taxNumber;
        this.activityName = activityName;
        this.activityCode = activityCode;
        this.address = address;
        this.taxReason = taxReason;
        this.stateNumber = stateNumber;
        this.status = status;
        this.registrationDate = registrationDate;
        this.liquidationDate = liquidationDate;
        this.liquidationDateDue = liquidationDateDue;
        this.ceos = ceos;
        this.ceo = ceo;
        this.ceoTitle = ceoTitle;
        this.shareholders = shareholders;
        this.statusCode = statusCode;
        this.statusName = statusName;
        this.internalCompanyId = internalCompanyId;
        this.okpo = okpo;
        this.pensionNumber = pensionNumber;
        this.socialNumber = socialNumber;
        this.okato = okato;
        this.okopf = okopf;
        this.okogu = okogu;
        this.okfs = okfs;
        this.dateOgrn = dateOgrn;
        this.shortName = shortName;
        this.fullName = fullName;
        this.latinName = latinName;
        this.restrictedByLaw = restrictedByLaw;
        this.liquidationReason = liquidationReason;
        this.regionCode = regionCode;
        this.regionName = regionName;
        this.actualAddress = actualAddress;
        this.actualAddressTown = actualAddressTown;
        this.actualAddressInvalidity = actualAddressInvalidity;
        this.actualAddressInvalidityReason = actualAddressInvalidityReason;
        this.okvedCode = okvedCode;
        this.okvedCodeVersion = okvedCodeVersion;
        this.okvedName = okvedName;
        this.okvedsOther = okvedsOther;
        this.oktmo = oktmo;
        this.taxOfficeCode = taxOfficeCode;
        this.taxOfficeName = taxOfficeName;
        this.actualRegisteredCapital = actualRegisteredCapital;
        this.undilutedRegisteredCapital = undilutedRegisteredCapital;
        this.undilutedRegisteredCapitalPercent = undilutedRegisteredCapitalPercent;
        this.actualRegisteredCapitalName = actualRegisteredCapitalName;
        this.holderOfSharesDataRestricted = holderOfSharesDataRestricted;
        this.holderOfSharesDataTaxNumber = holderOfSharesDataTaxNumber;
        this.holderOfSharesDataOGRN = holderOfSharesDataOGRN;
        this.holderOfSharesDataName = holderOfSharesDataName;
        this.updatedOn = updatedOn;
        this.finRepRows = finRepRows;
        this.finRepSimplifiedRows = finRepSimplifiedRows;
        this.finRepRowsRetro = finRepRowsRetro;
        this.finRepSimplifiedRetro = finRepSimplifiedRetro;
        this.minParsedYear = minParsedYear;
        this.maxParsedYear = maxParsedYear;
        this.nonExistingReports = nonExistingReports;
        this.existingReports = existingReports;
        this.simplifiedReports = simplifiedReports;
        this.finRepError = finRepError;
        this.kad_update_date = kad_update_date;
        this.kad_records_num = kad_records_num;
        this.kad_defendant = kad_defendant;
        this.kad_plaintiff = kad_plaintiff;
        this.kad_allRecords = kad_allRecords;
        this.licenses_all = licenses_all;
        this.licenses_valid = licenses_valid;
        this.licenses_passed = licenses_passed;
        this.licenses_unknown = licenses_unknown;
        this.trademarks_all = trademarks_all;
        this.trademarks_valid = trademarks_valid;
        this.trademarks_passed = trademarks_passed;
        this.trademarks_unknown = trademarks_unknown;
        this.contacts_phone = contacts_phone;
        this.contacts_email = contacts_email;
        this.contacts_web = contacts_web;
        this.smeString = smeString;
        this.smeSince = smeSince;
        this.smeTill = smeTill;
        this.smeMeasures_all = smeMeasures_all;
        this.smeMeasures_violation = smeMeasures_violation;
        this.smeMeasures_wo_violation = smeMeasures_wo_violation;
        this.taxSpecialRegimes = taxSpecialRegimes;
        this.taxesPaid = taxesPaid;
        this.subdivisions = subdivisions;
        this.searchKeyCEO = searchKeyCEO;
        this.searchKeyShareholder = searchKeyShareholder;
        this.whereFound = whereFound;
    }

    static fromJson(jsonData) {
        const json = jsonData['common'] || {};

        const parseBusinessType = (inn, ogrn) => {
            if (inn.length === 10) return BusinessType.CORPORATION;
            if (ogrn.length === 15) return BusinessType.BUSINESS_INDIVIDUAL;
            return BusinessType.PERSON;
        };

        const parseBusinessStatus = (statusCode) => {
            const statusInt = parseInt(statusCode, 10) || 0;
            if (statusInt === 1) return BusinessStatus.ACTIVE;
            if (statusInt === 111) return BusinessStatus.ACTIVE_DECREASING_SHARES;
            if (statusInt === 112) return BusinessStatus.ACTIVE_RELOCATING;
            if (statusInt === 114) return BusinessStatus.BANKRUPT;
            if (statusInt >= 121 && statusInt <= 139) return BusinessStatus.REORGANIZING;
            if (statusInt === 101 || (statusInt >= 105 && statusInt <= 107)) return BusinessStatus.LIQUIDATING;
            if (statusInt > 200 && statusInt < 700) return BusinessStatus.LIQUIDATED;
            return BusinessStatus.OTHER;
        };

        const parsingEntity = new Entity({
            type: parseBusinessType(json['TaxNumber'] || '', json['OGRN'] || ''),
            name: json['ShortName'] || '',
            taxNumber: json['TaxNumber'] || '',
            taxReason: json['TaxReason'] || '',
            stateNumber: json['OGRN'] || '',
            statusCode: json['StatusCode'] || '',
            statusName: json['StatusName'] || '',
            activityName: json['OkvedName'] || '',
            activityCode: json['OkvedCode'] || '',
            address: json['ActualAddress'] || '',
            status: parseBusinessStatus(json['StatusCode']),
            registrationDate: parseDateFromNullableString(json['DateRegistered']),
            liquidationDate: parseDateFromNullableString(json['DateLiquidation']),
            internalCompanyId: parseInt(json['InternalCompanyId'], 10) || null,
            okpo: json['OKPO'] || '',
            pensionNumber: json['PensionNumber'] || '',
            socialNumber: json['SocialNumber'] || '',
            okato: json['OKATO'] || '',
            okopf: json['OKOPF'] || '',
            okogu: json['OKOGU'] || '',
            okfs: json['OKFS'] || '',
            dateOgrn: parseDateFromNullableString(json['DateOGRN']),
            shortName: json['ShortName'] || '',
            fullName: json['FullName'] || '',
            latinName: json['LatinName'] || '',
            restrictedByLaw: (json['RestrictedByLaw'] || '') === '1',
            liquidationReason: json['LiquidationReason'] || '',
            regionCode: json['RegionCode'] || '',
            actualAddressTown: json['ActualAddressTown'] || '',
            actualAddressInvalidity: parseInt(json['ActualAddressInvalidity'], 10) || null,
            actualAddressInvalidityReason: json['ActualAddressInvalidityReason'] || '',
            okvedCode: json['OkvedCode'] || '',
            okvedCodeVersion: json['OkvedCodeVersion'] || '',
            okvedName: json['OkvedName'] || '',
            okvedsOther: json['OkvedsOther'] || '',
            oktmo: json['OKTMO'] || '',
            taxOfficeCode: json['TaxOfficeCode'] || '',
            taxOfficeName: json['TaxOfficeName'] || '',
            actualRegisteredCapital: parseFloat(json['ActualRegisteredCapital']) || null,
            actualRegisteredCapitalName: json['ActualRegisteredCapitalName'] || '',
            holderOfSharesDataRestricted: (json['HolderOfSharesDataRestricted'] || '') === '1',
            holderOfSharesDataTaxNumber: json['HolderOfSharesDataTaxNumber'] || '',
            holderOfSharesDataOGRN: json['HolderOfSharesDataOGRN'] || '',
            holderOfSharesDataName: json['HolderOfSharesDataName'] || '',
            regionName: json['RegionName'] || '',
            finRepError: json['finRepError'] || '',
            updatedOn: parseDateFromNullableString(json['UpdatedOn']),
        });

        try {
            if (json['ceos'] && Array.isArray(json['ceos'])) {
                parsingEntity.ceos = json['ceos'].map((ceoJson) => CEO.fromJson(ceoJson));
            }
        } catch (e) {
            myDebugPrint(`error on parsing ceos list from entity: ${e}`)
        }

        try {
            if (json['shareholders'] && Array.isArray(json['shareholders'])) {
                parsingEntity.shareholders = json['shareholders'].map((shareholderJson) =>
                    Shareholder.fromJson(shareholderJson)
                );
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (jsonData['finance']) {
                const finrepJson = jsonData.finance;
                const yearsOfReports = [];
                let minYear = new Date().getFullYear();
                let maxYear = 0;

                Object.entries(finrepJson).forEach(([key, value]) => {
                    const year = parseInt(key, 10) || 0;

                    if ((value.Version || '').toString().includes('notexist')) {
                        const liquidationDate = parseDateFromNullableString(jsonData.DateLiquidation);
                        if (!liquidationDate || liquidationDate.getFullYear() !== year) {
                            if (!parsingEntity.nonExistingReports) {
                                parsingEntity.nonExistingReports = [];
                            }
                            parsingEntity.nonExistingReports.push(year);
                        }
                    } else {
                        if (year > 2000 && year < new Date().getFullYear()) {
                            if ((value.Simplified || '0').toString() === '1') {
                                if (!parsingEntity.simplifiedReports) {
                                    parsingEntity.simplifiedReports = [];
                                }
                                parsingEntity.simplifiedReports.push(year);
                            }
                            yearsOfReports.push(year);
                            minYear = Math.min(minYear, year);
                            maxYear = Math.max(maxYear, year);
                        }
                    }
                });

                if (maxYear > 0) {
                    parsingEntity.minParsedYear = minYear;
                    parsingEntity.maxParsedYear = maxYear;
                    parsingEntity.existingReports = yearsOfReports;
                    parsingEntity.finRepRows = {};
                    parsingEntity.finRepRowsRetro = {};
                    parsingEntity.finRepSimplifiedRows = {};
                    parsingEntity.finRepSimplifiedRetro = {};

                    const directParse = ({ simplified, iYear, rebuildTotals = false }) => {
                        const finreprows = simplified ? parsingEntity.finRepSimplifiedRows : parsingEntity.finRepRows;
                        const indicatorsMapToUse = simplified ? indicatorsSimplifiedMap : indicatorsMap;
                        const yearData = finrepJson[iYear]?.values || finrepJson[iYear.toString()]?.values;

                        Object.entries(indicatorsMapToUse).forEach(([item, value]) => {
                            const iCode = value.key;
                            if (!finreprows[iCode]) {
                                finreprows[iCode] = new FinRepRowVals({ indicatorCode: iCode, mapValuesByYears: null });
                            }
                            let res;
                            try {
                                res = yearData?.[iCode]?.EndValue || 0.0;
                            } catch (e) {
                                res = parseFloat(yearData?.[iCode]?.EndValue) || parseFloat(yearData?.[parseInt(iCode)]?.EndValue) || 0.0;
                            }
                            finreprows[iCode].addYearVal(iYear, res);
                            if (simplified) {
                                parsingEntity.finRepRows = parsingEntity.finRepRows || {};
                                if (!parsingEntity.finRepRows[iCode]) {
                                    parsingEntity.finRepRows[iCode] = new FinRepRowVals({ indicatorCode: iCode, mapValuesByYears: null });
                                }
                                parsingEntity.finRepRows[iCode].addYearVal(iYear, res);
                            }
                        });

                        if (rebuildTotals) {
                            totalsCodesForSimpleRegularMapping.forEach((total) => {
                                let iVal = 0;
                                if ((simpleCasualMapping[total]?.length || 0) > 1) {
                                    simpleCasualMapping[total].forEach((element) => {
                                        const tCodeUnsigned = element.slice(-4);
                                        const sign = element.length > 4 ? -1 : 1;
                                        iVal += sign * (parseFloat(yearData?.[tCodeUnsigned]?.EndValue) || parseFloat(yearData?.[parseInt(tCodeUnsigned)]?.EndValue) || 0);
                                    });
                                    if (!parsingEntity.finRepRows[total]) {
                                        parsingEntity.finRepRows[total] = new FinRepRowVals({ indicatorCode: total, mapValuesByYears: null });
                                    }
                                    parsingEntity.finRepRows[total].addYearVal(iYear, iVal);
                                }
                            });
                        }
                    };

                    for (let iYear = minYear; iYear <= maxYear; iYear++) {
                        const isSimplified = (finrepJson[iYear.toString()]?.Simplified || '0').toString() === '1';
                        directParse({ simplified: isSimplified, iYear, rebuildTotals: isSimplified });
                        if (!isSimplified) {
                            parsingEntity.finRepSimplifiedRows = parsingEntity.finRepSimplifiedRows || {};
                            Object.entries(indicatorsSimplifiedMap).forEach(([item, value]) => {
                                let key = value.key;
                                if ((simpleCasualMapping[key]?.length || 0) === 1) {
                                    parsingEntity.finRepSimplifiedRows[key] = parsingEntity.finRepSimplifiedRows[key] || new FinRepRowVals({ indicatorCode: key, mapValuesByYears: null });
                                    parsingEntity.finRepSimplifiedRows[key].addYearVal(iYear, parsingEntity.finRepRows[key]?.mapValuesByYears?.[iYear]);
                                } else if ((simpleCasualMapping[key]?.length || 0) > 1) {
                                    const yearData = finrepJson[iYear]?.values || finrepJson[iYear.toString()]?.values;
                                    if (yearData && Object.keys(yearData).length > 0) {
                                        let cumVal = 0;
                                        let currentMax = 0;
                                        let elementWithMax;

                                        simpleCasualMapping[key].forEach((element) => {
                                            const tCodeUnsigned = element.slice(-4);
                                            const sign = element.length > 4 ? -1 : 1;
                                            const thisVal = sign * (parseFloat(yearData?.[tCodeUnsigned]?.EndValue) || parseFloat(yearData?.[parseInt(tCodeUnsigned)]?.EndValue) || 0);
                                            if (currentMax < thisVal) {
                                                currentMax = thisVal;
                                                elementWithMax = tCodeUnsigned;
                                            }
                                            cumVal += thisVal;
                                        });

                                        if (key.slice(-2) === '00') {
                                            parsingEntity.finRepSimplifiedRows[key] = parsingEntity.finRepSimplifiedRows[key] || new FinRepRowVals({ indicatorCode: key, mapValuesByYears: null });
                                            parsingEntity.finRepSimplifiedRows[key].addYearVal(iYear, cumVal);
                                        } else if (elementWithMax && key === simpleCasualMapping[key][0].padStart(5, '0').slice(1)) {
                                            parsingEntity.finRepSimplifiedRows[elementWithMax] = parsingEntity.finRepSimplifiedRows[elementWithMax] || new FinRepRowVals({ indicatorCode: elementWithMax, mapValuesByYears: null });
                                            parsingEntity.finRepSimplifiedRows[elementWithMax].addYearVal(iYear, cumVal);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['kad_records_num']) {
                parsingEntity.kad_records_num = parseInt(json['kad_records_num'], 10) || null;
                parsingEntity.kad_update_date = parseDateFromNullableString(json['kad_update_date']);
                if (json['kad_plaintiff'] && Array.isArray(json['kad_plaintiff'])) {
                    parsingEntity.kad_plaintiff = json['kad_plaintiff'].map((kadJson) =>
                        Kad.fromJson(kadJson, KadPartyType.PLAINTIFF)
                    );
                    parsingEntity.kad_allRecords = [...parsingEntity.kad_plaintiff];
                }
                if (json['kad_defendant'] && Array.isArray(json['kad_defendant'])) {
                    parsingEntity.kad_defendant = json['kad_defendant'].map((kadJson) =>
                        Kad.fromJson(kadJson, KadPartyType.DEFENDANT)
                    );
                    parsingEntity.kad_allRecords = [
                        ...parsingEntity.kad_plaintiff,
                        ...parsingEntity.kad_defendant,
                    ];
                }
                parsingEntity.kad_allRecords.sort((a, b) => (b.dateOpened || 0) - (a.dateOpened || 0));
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['Licenses'] && Array.isArray(json['Licenses'])) {
                parsingEntity.licenses_all = [];
                parsingEntity.licenses_valid = [];
                parsingEntity.licenses_passed = [];
                parsingEntity.licenses_unknown = [];
                json['Licenses'].forEach((licenseJson) => {
                    const license = License.fromJson(licenseJson);
                    if (!license.validTill) {
                        license.validity = LicenseAndTrademarkValidity.UNKNOWN;
                        parsingEntity.licenses_unknown.push(license);
                    } else if (license.validTill <= new Date()) {
                        license.validity = LicenseAndTrademarkValidity.PASSED;
                        parsingEntity.licenses_passed.push(license);
                    } else {
                        license.validity = LicenseAndTrademarkValidity.VALID;
                        parsingEntity.licenses_valid.push(license);
                    }
                    parsingEntity.licenses_all.push(license);
                });
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['trademarks'] && Array.isArray(json['trademarks'])) {
                parsingEntity.trademarks_all = [];
                parsingEntity.trademarks_valid = [];
                parsingEntity.trademarks_passed = [];
                parsingEntity.trademarks_unknown = [];
                json['trademarks'].forEach((trademarkJson) => {
                    const trademark = Trademark.fromJson(trademarkJson);
                    if (!trademark.validTill) {
                        trademark.validity = LicenseAndTrademarkValidity.UNKNOWN;
                        parsingEntity.trademarks_unknown.push(trademark);
                    } else if (trademark.validTill <= new Date()) {
                        trademark.validity = LicenseAndTrademarkValidity.PASSED;
                        parsingEntity.trademarks_passed.push(trademark);
                    } else {
                        trademark.validity = LicenseAndTrademarkValidity.VALID;
                        parsingEntity.trademarks_valid.push(trademark);
                    }
                    parsingEntity.trademarks_all.push(trademark);
                });
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['contacts']) {
                const contacts = json['contacts'];
                if (contacts['Тел'] && Array.isArray(contacts['Тел'])) {
                    parsingEntity.contacts_phone = contacts['Тел'].map((phone) => phone.toString());
                }
                if (contacts['Емэйл'] && Array.isArray(contacts['Емэйл'])) {
                    parsingEntity.contacts_email = contacts['Емэйл'].map((email) => email.toString());
                }
                if (contacts['ВебСайт']) {
                    parsingEntity.contacts_web = Array.isArray(contacts['ВебСайт'])
                        ? contacts['ВебСайт'].map((web) => web.toString())
                        : [contacts['ВебСайт'].toString()];
                }
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['sme_list']) {
                if (json['sme_list']['Кат']) {
                    parsingEntity.smeString = json['sme_list']['Кат'].toLowerCase();
                    parsingEntity.smeSince = parseDateFromNullableString(json['sme_list']['ДатаВкл']);
                }
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['sme_measures'] && Array.isArray(json['sme_measures'])) {
                parsingEntity.smeMeasures_all = json['sme_measures'].map((measureJson) =>
                    SMEMeasure.fromJson(measureJson)
                );
                parsingEntity.smeMeasures_violation = parsingEntity.smeMeasures_all.filter((measure) => measure.violation);
                parsingEntity.smeMeasures_wo_violation = parsingEntity.smeMeasures_all.filter((measure) => !measure.violation);
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['taxes']) {
                if (json['taxes']['ОсобРежим'] && Array.isArray(json['taxes']['ОсобРежим'])) {
                    parsingEntity.taxSpecialRegimes = json['taxes']['ОсобРежим'].map((regime) => regime.toString());
                }
                parsingEntity.taxesPaid = [TaxesPaid.fromJson(json['taxes'])];
            }
        } catch (e) {
            myDebugPrint(e)
        }

        try {
            if (json['subdivisions'] && typeof json['subdivisions'] === 'object') {
                parsingEntity.subdivisions = [];
                Object.entries(json['subdivisions']).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach((element) => {
                            parsingEntity.subdivisions.push(Subdivision.fromJson(element, key));
                        });
                    }
                });
            }
        } catch (e) {
            myDebugPrint(e)
        }

        return parsingEntity;
    }

    getTaxStateCodes() {
        return `ИНН ${this.taxNumber} ${this.taxReason ? `КПП ${this.taxReason} ` : ''}ОГРН ${this.stateNumber}`;
    }

    buildAllActivities() {
        const mainActivity = `${this.activityCode} - ${this.activityName}`;
        return `${mainActivity}\n${this.okvedsOther || ''}`;
    }

    buildRecentRevenue() {
        if (!this.finRepRows) return 'Сведения о выручке отсутствуют';
        const revenueData = this.finRepRows['2110'];
        if (!revenueData) return 'Сведения о выручке отсутствуют';
        return revenueData.buildRecent(
            { lastYearProposition: 'за ', prevYearProposition: 'за ' }
        ) || '';
    }

    buildRecentNetProfit() {
        if (!this.finRepRows) return 'Сведения о прибыли отсутствуют';
        const revenueData = this.finRepRows['2400'];
        if (!revenueData) return 'Сведения о прибыли отсутствуют';
        return revenueData.buildRecent(
            { lastYearProposition: 'за ', prevYearProposition: 'за ' }
        ) || '';
    }

    buildRecentAssets() {
        if (!this.finRepRows) return 'Сведения об активах отсутствуют';
        const revenueData = this.finRepRows['1600'];
        if (!revenueData) return 'Сведения об активах отсутствуют';
        return revenueData.buildRecent(
            { lastYearProposition: 'на конец ', prevYearProposition: 'на конец ' }
        ) || '';
    }

    buildFinRepMap({ firstDigitInIndCode, years, division, roundDigits }) {
        const res = [];
        Object.entries(indicatorsMap).forEach(([item, value]) => {
            if (this.finRepRows[value.key]
                && value.key.startsWith(firstDigitInIndCode.toString())
            ) {
                let activeRow = this.finRepRows[value.key];
                const row = activeRow.buildRowMap({
                    yearsListFull: years,
                    division: division,
                    roundDigits: roundDigits,
                    simplified: false
                });
                if (row) res.push(row);
            }
        });
        // Object.entries(this.finRepRows || {}).forEach(([key, value]) => {
        //     if (key.startsWith(firstDigitInIndCode.toString())) {
        //         const row = value.buildRowMap({ yearsListFull: years, division: division, roundDigits: roundDigits, simplified: false });
        //         if (row) res.push(row);
        //     }
        // });
        return res;
    }

    buildFinSimplifiedRepMap({ firstDigitInIndCode, years, division, roundDigits }) {
        const res = [];
        Object.entries(indicatorsSimplifiedMap).forEach(([item, value]) => {
            if (this.finRepSimplifiedRows[value.key]
                && value.key.startsWith(firstDigitInIndCode.toString())
            ) {
                let activeRow = this.finRepSimplifiedRows[value.key];
                const row = activeRow.buildRowMap({
                    yearsListFull: years,
                    division: division,
                    roundDigits: roundDigits,
                    simplified: true
                });
                if (row) res.push(row);
            }
        });
        // Object.entries(this.finRepSimplifiedRows || {}).forEach(([key, value]) => {
        //     if (key.startsWith(firstDigitInIndCode.toString())) {
        //         const row = value.buildRowMap({ yearsListFull: years, division: division, roundDigits: roundDigits, simplified: true });
        //         if (row) res.push(row);
        //     }
        // });
        return res;
    }
}

export default Entity;
